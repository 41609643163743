<template>
  <div v-if="visible" class="modal-wrapper">
    <div @click="toggle" class="overlay"></div>
    <div class="modal-card">
      <button @click="toggle" class="close-btn"><i class="fas fa-times"></i></button>
        <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullScreenModal",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    toggle: {
      type: Function,
      required: true
    }
  },
}
</script>

<style scoped>
.modal-wrapper {
  @apply absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center z-20;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  @apply fixed top-0 bottom-0 left-0 right-0
}

.modal-card {
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  @apply bg-gray-50 z-20 py-6 px-6 pt-10 rounded fixed;
}

.close-btn {
  @apply text-xl px-2 hover:text-red-600 text-red-500 rounded absolute right-2 top-2;
}
</style>
