<template>
  <div class="grid grid-cols-2">
    <CombinerData :culture-id="cultureId" :is-history="isHistory"/><!-- on-change-combine-mode-cb="" on-change-combine-freq-cb=""-->
    <FitHuntModel :culture-id="cultureId" /><!-- update-hunt-callback="" -->
  </div>
</template>

<script>
import CombinerData from "@/components/model/CombinerData.vue";
import FitHuntModel from "@/components/model/FitHuntModel.vue";

export default {
  name: "HuntCurve",
  components: {FitHuntModel, CombinerData},
  props:{
    cultureId:{
      type:[String,Number],
      required:true
    },
    isHistory:{
      type:Boolean,
      required:false,
      default: false
    }
  },
  data:function(){
    return {
    }
  }
}
</script>

<style scoped>

</style>