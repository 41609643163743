<template>
  <div>
    <!-- Conteneur pour la note -->
    <div class="note-container">
      <p style="color: red;"><strong>{{ $t('pages.culture_create.explanation.note') }}</strong></p>
    </div>

    <div v-if="isLoaded" class="card">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(submitForm)">

          <!-- Nom de la culture -->
          <div class="max-w-sm">
            <ValidationProvider :name="$t('pages.culture_create.fields.name')" rules="required|max:255" v-slot="{ errors }">
              <label class="form__label">{{ $t('pages.culture_create.fields.name') }}<Required/></label>
              <input v-model="form.name" class="form__input w-full" type="text">
              <p class="form__error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <!-- Type de culture (extérieur / intérieur) -->
          <div class="mt-4">
            <label class="form__label">{{ $t('pages.culture_create.fields.cultureType') }}<Required/></label>
            <input id="radio-outside" type="radio" value="1" v-model.number="form.outside">
            <label for="radio-outside" class="text-gray-600"> {{ $t('pages.culture_create.fields.outside') }}</label>

            <input id="radio-inside" class="ml-4" type="radio" value="0" v-model.number="form.outside">
            <label for="radio-inside" class="text-gray-600"> {{ $t('pages.culture_create.fields.inside') }}</label>
          </div>

          <!-- Espèce -->
          <div class="mt-4 flex space-x-4">
            <div class="w-40">
              <label class="form__label">{{ $t('pages.culture_create.fields.species') }}<Required/></label>
              <select v-model.number="form.speciesId" @change="updateVarieties" class="form__input w-full">
                <option v-for="cultureType in cultureTypes" :key="cultureType.id" :value="cultureType.id">
                  {{ $t('backend_trans_keys.' + cultureType.typeTransKey) }}
                </option>
              </select>
            </div>

            <!-- Variété -->
            <div class="w-48">
              <label class="form__label">{{ $t('pages.culture_create.fields.variety') }}<Required/></label>
              <select v-model.number="form.varietyId" class="form__input w-full">
                <option v-for="variety in filteredVarieties" :key="variety.id" :value="variety.id">
                  {{ $t('backend_trans_keys.' + variety.varietyTransKey) }}
                </option>
              </select>
            </div>
          </div>

          <!-- Date de plantation -->
          <div class="mt-4 max-w-sm">
            <ValidationProvider :name="$t('pages.culture_create.fields.plantationDate')" v-slot="{ errors }">
              <label class="form__label">{{ $t('pages.culture_create.fields.plantationDate') }}</label>
              <input v-model="form.plantationDate" class="form__input w-full" type="date">
              <p class="form__error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <!-- Début des mesures -->
          <div class="mt-4 max-w-sm">
            <ValidationProvider :name="$t('pages.culture_create.fields.nouaisonDate')" v-slot="{ errors }">
              <label class="form__label">{{ $t('pages.culture_create.fields.nouaisonDate') }}</label>
              <input v-model="form.nouaisonDate" class="form__input w-full" type="date">
              <p class="form__error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <!-- Titre Phénologie-->
          <div class="mt-4 max-w-3xl border border-gray-300 rounded p-4">
            <h3 class="text-lg font-bold underline">{{ $t('pages.culture_create.fields.phenology') }}</h3>

            <!-- Pleine floraison -->
            <div class="flex space-x-2 mt-4"> 
              <div class="flex-1 max-w-xs">
                <label class="form__label">{{ $t('pages.culture_create.fields.fullBloom') }}</label>
                <input v-model="form.fullBloom" class="form__input" type="text">
              </div>

              <!-- Stade T -->
              <div class="flex-1 max-w-xs">
                <label class="form__label">{{ $t('pages.culture_create.fields.stageT') }}</label>
                <input v-model="form.stageT" class="form__input" type="text">
              </div>

              <!-- Prévision récolte -->
              <div class="flex-1 max-w-xs">
                <label class="form__label">{{ $t('pages.culture_create.fields.harvestForecast') }}</label>
                <input v-model="form.harvestForecast" class="form__input" type="text">
              </div>
            </div>
          </div>

          <!-- Carte interactive -->
          <div class="mt-4">
            <label class="form__label">{{ $t('pages.culture_create.fields.interactiveMapType.title') }}<Required/></label>
            <select v-model.number="form.interactiveMapType" class="form__input cursor-pointer">
              <option value="0">{{ $t('pages.culture_create.fields.interactiveMapType.none') }}</option>
              <option value="1">{{ $t('pages.culture_create.fields.interactiveMapType.geo') }}</option>
              <option value="2">{{ $t('pages.culture_create.fields.interactiveMapType.custom') }}</option>
            </select>

            <!-- Upload custom map file -->
            <div v-if="form.interactiveMapType === 2" class="mt-4 flex flex items-center">
              <ValidationProvider name="fileDrop" v-slot="{ errors }">
                <file-drop-zone @onSuccessUploadFile="onSuccessUploadFile" @onError="onFileDropError" @onDeleteUploadFile="onDeleteUploadFile" />
                <p class="form__error">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <!-- Bouton de création -->
          <button class="mt-6 btn" :class="{ 'btn--success': !invalid,'btn--disabled': invalid }">
            <i class="fas fa-check mr-2"></i>{{ $t('pages.culture_create.create') }}
          </button>
        </form>

        <!-- Ajout du texte explicatif sous le bouton "Créer" -->
        <div class="mt-4 text-gray-600 explanatory-text">
          <h3><u>{{ $t('pages.culture_create.explanation.descriptionTitle') }}</u></h3>
          <p>{{ $t('pages.culture_create.explanation.plantationDateDescr') }}</p>
          <p>{{ $t('pages.culture_create.explanation.nouaisonDateDescr') }}</p>
          <p>{{ $t('pages.culture_create.explanation.phenologyDescr') }}</p>
          <p>{{ $t('pages.culture_create.explanation.fullBloomDescr') }}</p>
          <p>{{ $t('pages.culture_create.explanation.stageTDescr') }}</p>
          <p>{{ $t('pages.culture_create.explanation.harvestForecastDescr') }}</p>
          <p><strong>{{ $t('pages.culture_create.explanation.agrometeoLink') }}</strong></p>
          <p>{{ $t('pages.culture_create.explanation.interactiveMapDescr') }}</p>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Required from "../../../components/forms/Required";
import {API_CULTURE_TYPES_INDEX, API_CULTURES_STORE} from "../../../utils/constants";
import {apiGetRequest, apiPostRequest, displayAlertSuccess} from "../../../utils";
import FileDropZone from "../../../components/forms/FileDropZone";

export default {
  name: "CultureCreate",
  components: {FileDropZone, Required},
  props: {
    farmerId: {
      type: [Number, String],
      required: false // Possibly not here if we come directly from the link (/cultures/create)
    }
  },
  data: function() {
    return {
      isLoaded: false,
      form: {
        farmerId: this.farmerId,
        name: null,
        nouaisonDate: null,
        plantationDate: null,
        outside: 1,
        speciesId: -1,
        varietyId: -1,
        interactiveMapType: 0,
        customMapFile: null,
        fullBloom:"",
        stageT:"",
        harvestForecast:""
      },
      cultureTypes: [],
      filteredVarieties: [] //Stocker les variétés filtrées
    }
  },
  mounted() {
    if(this.farmerId === undefined){
      this.form.farmerId = this.$store.state.user.id
    }
    this.loadVarieties()
  },
  methods: {
    loadVarieties: function(){
      this.isLoaded = false;
      apiGetRequest(API_CULTURE_TYPES_INDEX)
        .then((res) => {
          this.cultureTypes = res.data;
          if(this.cultureTypes.length > 0) {
            this.form.speciesId = this.cultureTypes[0].id; // Sélectionne la première espèce par défaut
            this.updateVarieties(); // Met à jour les variétés liées à la première espèce
          }
          this.isLoaded = true;
        })
    },
    updateVarieties() {
      // Filtrer les variétés en fonction de l'espèce sélectionnée
      const selectedCultureType = this.cultureTypes.find(ct => ct.id === this.form.speciesId);
      this.filteredVarieties = selectedCultureType ? selectedCultureType.varieties : [];
      
      // Si aucune variété n'est trouvée, réinitialiser la sélection de variété
      if (this.filteredVarieties.length > 0) {
        this.form.varietyId = this.filteredVarieties[0].id; // Sélectionner la première variété par défaut
      } else {
        this.form.varietyId = -1; // Réinitialiser si aucune variété n'est disponible
      }
    },
    onFileDropError: function(error){
      this.form.customMapFile = null
      const errors = this.$refs.form.errors
      errors['fileDrop'] = error.message
      this.$refs.form.setErrors(errors)
    },
    onSuccessUploadFile: function (file) {
      const errors = this.$refs.form.errors
      errors['fileDrop'] = null
      this.$refs.form.setErrors(errors)
      this.form.customMapFile = file
    },
    onDeleteUploadFile: function(){
      this.form.customMapFile = null
    },
    submitForm: function() {
      if(this.form.interactiveMapType === 2 && this.form.customMapFile === null){
        const errors = this.$refs.form.errors
        errors['fileDrop'] = this.$t('pages.culture_create.errorMapFileRequired')
        this.$refs.form.setErrors(errors)
      }else{
        const formData = new FormData()
        formData.append('farmerId', this.form.farmerId)
        formData.append('name', this.form.name)
        formData.append('outside', this.form.outside)
        formData.append('speciesId', this.form.speciesId)
        formData.append('varietyId', this.form.varietyId)
        if(this.form.nouaisonDate !== null){
          formData.append('nouaisonDate', this.form.nouaisonDate)
        }
        formData.append('interactiveMapType', this.form.interactiveMapType)
        if(this.form.interactiveMapType === 2){
          formData.append('customMapFile', this.form.customMapFile, this.form.customMapFile.name)
        }

        apiPostRequest(API_CULTURES_STORE, formData, true)
          .then(() => {
            const successMsg = this.$t('pages.culture_create.success', {name: this.form.name})
            displayAlertSuccess(successMsg)
            if(this.$store.getters.userIsAdmin){
              this.$router.push({name: "farmer_show", params: {id: this.form.farmerId}})
            }else{
              this.$router.push({name: "culture_index"})
            }
          })
          .catch((e) => {
            if(e.response.data.fields.mapFile === "mimeType"){
              const errors = this.$refs.form.errors
              errors['fileDrop'] = this.$t('errors.common.invalidMimeType')
              this.$refs.form.setErrors(errors)
            }
          })
      }
    },
  }
}
</script>

<style>
.note-container {
  position: absolute; /* Positionnement absolu pour un placement précis */
  top: 95px; /* placer plus haut ou plus bas */
  right: 40px; /* Ajustez cette valeur pour le placer plus à droite ou à gauche */
  z-index: 10; /* S'assure qu'il est au-dessus des autres éléments */
  background-color: white; 
  padding: 10px; 
}

.explanatory-text {
  position: absolute; /* Position absolue pour un placement précis */
  top: 180px; /* Ajustez cette valeur pour le positionner verticalement */
  right: 90px; /* Ajustez cette valeur pour le déplacer horizontalement à droite */
  width: 650px; /* Largeur de la boîte pour contrôler la largeur du texte */
  background-color: white; /* Fond blanc pour le texte */
  padding: 15px; /* Espacement interne */
  border: 1px solid #ccc; /* Bordure optionnelle pour délimiter le texte */
  z-index: 10; /* S'assurer qu'il reste au-dessus des autres éléments si nécessaire */
}
</style>