<template>
    <div class="flex items-center justify-center">
      <!-- Drop zone -->
      <div @click="dropZoneOnClick"
           @dragover.stop.prevent="dropZoneOnDragOver"
           @dragleave="dropZoneOnDragLeave"
           @drop.stop.prevent="dropZoneOnDrop"
           class="cursor-pointer border-2 border-dashed w-96 text-center"
           :class="cursorIsOver ? 'bg-green-100 border-green-500' : 'bg-gray-50 border-gray-300'"
      >
        <div class="pointer-events-none px-4 py-6"> <!-- Disable all pointer events this way only have parent drag/drop event triggered-->
          <input id="dropZoneInput" @change="dropZoneInputOnChange" type="file" accept="image/*" class="w-0 h-0 opacity-0 overflow-hidden">
          <i class="fas fa-images text-blue-500 text-3xl"></i>
          <p class="text-gray-600 font-medium">{{ $t('components.fileDropZone.titlePre') }} <span class="text-blue-500">{{ $t('components.fileDropZone.titlePost') }}</span></p>
          <p class="text-gray-400 font-light text-xs">{{ $t('components.fileDropZone.supportedTypes') }}</p>
        </div>
      </div>
      <!-- Dropped file -->
      <div v-if="currentFile !== null" class="ml-6">
        <div class="flex items-center justify-center">
          <img id="uploadedFileThumbnail" class="outline-none border-none bg-no-repeat bg-contain bg-center" style="width: 100px; height: 100px" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
          <div class="ml-2">
            <p class="text-gray-600 font-normal">{{ currentFile.name }}</p>
            <p class="-mt-1 text-gray-400 text-xs">{{ dropZoneFileSizeKo }} KB</p>
            <i @click="removeFile" class="mt-2 fas fa-trash-alt text-red-500 cursor-pointer"></i>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "FileDropZone",
  data: function() {
    return {
      cursorIsOver: false,
      currentFile: null
    }
  },
  computed: {
    dropZoneFileSizeKo: function() {
      return Math.ceil(this.currentFile.size / 1024)
    }
  },
  methods: {
    dropZoneOnClick: function() {
      document.getElementById("dropZoneInput").click()
    },
    /**
     * /!\ Mandatory to use the dragOver callback and not the dragEnter to works !!! /!\
     * Called when we drag a file over the drop zone
     */
    dropZoneOnDragOver: function() {
      if(!this.cursorIsOver){
        this.cursorIsOver = true
      }
    },
    /**
     * Called when we drag a file and leave the drop zone
     */
    dropZoneOnDragLeave: function() {
      this.cursorIsOver = false
    },
    /**
     * Called when we select or quit the input file default system chose modal (triggered when we click on the drop zone)
     * @param e The event
     */
    dropZoneInputOnChange: function(e) {
      const file = e.target.files[0]
      if(file !== undefined){ // Prevent bug if we cancel the choose file modal
        this.uploadFile(file)
      }
      e.target.value = null // Prevent bug when add file -> remove -> add same file. This way we reset all the time the input file value
    },
    /**
     * Called when we drop a file in the zone
     * It will trigger the upload dropped file process
     */
    dropZoneOnDrop: function(e) {
      this.dropZoneOnDragLeave()
      const file = e.dataTransfer.files[0]
      this.uploadFile(file)
    },
    /**
     * Consist of save in a class var the current chosen file (only if it's really an image jpeg or png)
     * The name + size of file will be displayed. Also, we'll construct a thumbnail of the selected file
     */
    uploadFile: function(file) {
      const FILE_SIZE_LIMIT_MB = 15

      this.currentFile = null // Remove last file

      let error = {
        type: null,
        message: null
      }

      // Accept only image jpeg or png
      if(!(file.type === 'image/jpeg' || file.type === 'image/png')){
        error.type = 'fileType'
        error.message = this.$t('components.fileDropZone.errors.supportedTypes')
      }
      // File size limited to 15Mib max
      else if(file.size / 1024 / 1024 > FILE_SIZE_LIMIT_MB) {
        error.type = 'fileSize'
        error.message = this.$t('components.fileDropZone.errors.sizeLimit')
      }

      // No error occurred
      if(error.type === null){
        this.currentFile = file
        const reader = new FileReader()
        reader.readAsDataURL(this.currentFile)
        reader.onload = function (readerEvent) {
          document.getElementById('uploadedFileThumbnail').style.backgroundImage = "url(" + readerEvent.target.result + ")";
        };
        this.$emit('onSuccessUploadFile', file)
      }else{
        this.$emit('onError', error)
      }
    },
    /**
     * Deleted the current selected file
     */
    removeFile: function() {
      this.currentFile = null
      this.$emit('onDeleteUploadFile')
    }
  }

}
</script>

<style scoped>

</style>
