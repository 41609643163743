import { render, staticRenderFns } from "./Inventory.vue?vue&type=template&id=e2266358&scoped=true"
import script from "./Inventory.vue?vue&type=script&lang=js"
export * from "./Inventory.vue?vue&type=script&lang=js"
import style0 from "./Inventory.vue?vue&type=style&index=0&id=e2266358&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2266358",
  null
  
)

export default component.exports