import store from "../store";
import {generatePageHeadTitle} from "../utils";
import router from "./index";

/**
 * All not authenticated user trying to access an auth route will be redirect
 * to the login page
 *
 * To check the auth, we only check the presence of a user email in the stored JWT access token.
 * Real JWT signature test will be done by the backend when we'll request the API for fetching data.
 *
 * @param to the target Route Object being navigated to
 * @param from the current route being navigated away from
 * @param next this function must be called to resolve the hook
 */
export function checkAccessMiddleware(to, from, next) {
  const needAuth = to.meta.auth

  if(needAuth === undefined || (needAuth === true && store.getters.userEmail === null)){
    next({name: 'login'})
  }else{
    next()
  }
}

/**
 * Automatically redirect to /inventory if we are already authenticated and want to access a public view.
 * @param to the target Route Object being navigated to
 * @param from the current route being navigated away from
 * @param next this function must be called to resolve the hook
 */
export function alreadyAuth(to, from, next) {
  if(to.meta.auth === false && store.getters.userEmail !== null) {
    next({name: 'inventory'})
  }else{
    next()
  }
}

/**
 * Set the right Layout depends on the view we navigate to and the route meta auth field.
 * We commit changes only if needed.
 * @param to the target Route Object being navigated to
 * @param from the current route being navigated away from
 * @param next this function must be called to resolve the hook
 */
export function setLayout(to, from, next) {
  // Auth route
  if(to.meta.auth === true){
    if(store.state.layout === 'PublicLayout'){
      store.commit('setLayout', 'AppLayout')
    }
  }
  // Public route
  else{
    if(store.state.layout === 'AppLayout'){
      store.commit('setLayout', 'PublicLayout')
    }
  }

  next()
}

/**
 * Automatically set the header title to the page we navigate to
 * @param to the target Route Object being navigated to
 * @param from the current route being navigated away from
 * @param next this function must be called to resolve the hook
 */
export function setHeadTitle(to, from, next) {
  window.document.title = generatePageHeadTitle(to.name)
  next()
}

/**
 * For auth route, check if the authenticated user has at least one of the roles defined in the
 * meta "roles" key in the route definition we want to navigate to.
 * If the user doesn't have the rights, redirect to the 403 error page
 * @param to the target Route Object being navigated to
 * @param from the current route being navigated away from
 * @param next this function must be called to resolve the hook
 */
export function hasRole(to, from, next){
  // Auth route
  if(to.meta.auth === true){
    let hasRole = false
    for(const userRole of store.getters.userRoles){
      if(to.meta.roles.includes(userRole)){
        hasRole = true
        break
      }
    }
    if(hasRole){
      next()
    }else{
      router.push({name: 'error_4xx', params: {statusCode: 403}})
    }
  }
  // Public route
  else{
    next()
  }
}

/**
 * Routes may have a meta attribute "disabled" (boolean)
 * Redirect to a 404 not found if the route is set as disabled
 * @param to the target Route Object being navigated to
 * @param from the current route being navigated away from
 * @param next this function must be called to resolve the hook
 */
export function disabledRoutes(to, from, next) {
  if(to.meta.disabled){
    router.push({name: 'error_4xx', params: {statusCode: 404}})
  }else{
    next()
  }
}
