<template>
  <div>
    <label>{{ $t('pages.sensor_show.timePeriod-title') + " : " }}</label>
    <select v-model.number="selectedTimePeriod" @change="timePeriodChanged" class="border-gray-200 border outline-none px-2 py-1">
      <option value="1">{{ $t('pages.sensor_show.timePeriod-7days') }}</option>
      <option value="2">{{ $t('pages.sensor_show.timePeriod-48hours') }}</option>
      <option value="3">{{ $t('pages.sensor_show.timePeriod-24hours') }}</option>
      <option value="4">{{ $t('pages.sensor_show.timePeriod-all') }}</option>
      <option value="5">{{ $t('pages.sensor_show.timePeriod-custom') }}</option>
    </select>
    <div v-if="showDatePicker" class="flex  mt-5 gap-3">
      <div>
        <label>
          {{$t('pages.sensor_show.timePeriod-custom-start')}}:

        </label>
        <input class="form__input" type="date"  v-model="startDate" />
      </div>
      <div>
        <label>
          {{$t('pages.sensor_show.timePeriod-custom-end')}}:
        </label>
        <input class="form__input" type="date"  v-model="endDate" />
      </div>
      <div>
        <button class="btn btn--primary text-center" @click="submitCustomDate"><i class="fas fa-sync"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectTimePeriod",
  props: {
    defaultValue: {
      type: Number,
      required: true
    },
    defaultShowDataPicker: {
      type: Boolean,
      required: true
    },
    defaultStartDate: {
      type : String,
      required: true,
    },
    defaultEndDate : {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      selectedTimePeriod: this.defaultValue,
      showDatePicker : this.defaultShowDataPicker,
      startDate: this.defaultStartDate,
      endDate : this.defaultEndDate
    }
  },
  methods: {
    submitCustomDate: function(){
      this.$emit('submitCustomDate', {start: this.startDate, end : this.endDate})
    },
    timePeriodChanged: function(){
      // If the user chooses a fixed date range call the parent,
      // Otherwise, show date picker to enter the date
      this.$emit('valueChanged', this.selectedTimePeriod)
      this.showDatePicker = this.selectedTimePeriod === 5;
    }
  }
}
</script>

<style scoped>

</style>
