<template>
  <div class="full-page-container flex flex-col">

    <!-- Refresh button -->
    <div class="-mt-5">
      <btn-with-loader classBtnType="btn--primary" style="width: fit-content" :loading="logs.isLoading" @clicked="loadLogs">
        <i class="fas fa-sync"></i>
      </btn-with-loader>
      <span class="ml-2">{{ $t('pages.server_logs.info') }}</span>
    </div>


    <!-- Logs zone -->
    <div class="logs-wrapper ">
      <p v-if="logs.isLoading">{{ $t('pages.server_logs.loading') }}</p>
      <div v-else class="whitespace-pre-wrap break-all">
        {{ logs.data }}
      </div>
    </div>
  </div>
</template>

<script>
import {apiGetRequest} from "../../utils";
import {API_SERVER_LOGS} from "../../utils/constants";
import BtnWithLoader from "../../components/general/btn-with-loader";

export default {
  name: "ServerLogs",
  components: {BtnWithLoader},
  data: function() {
    return {
      logs: {
        isLoaded: false,
        isLoading: false,
        data: null
      }
    }
  },
  mounted() {
    this.loadLogs()
  },
  methods: {
    /**
     * Load all server logs
     */
    loadLogs: function() {
      if(!this.logs.isLoading) {
        this.logs.isLoaded = false
        this.logs.isLoading = true

        apiGetRequest(API_SERVER_LOGS)
            .then((res) => {
              if(res.data === ""){
                res.data = this.$t('pages.server_logs.noData')
              }
              this.logs.data = res.data
              this.logs.isLoading = false
              this.logs.isLoaded = true
            })
            .catch(() => {
              this.logs.isLoading = false
            })
      }
    }
  },
}
</script>

<style scoped>
  .full-page-container {
    height: calc(100vh - 136px); /* need this way to have full height space available */
  }
  .logs-wrapper {
    background-color: #2B2B2B;
    color: #BBBBBB;
    @apply mt-3 p-4 overflow-y-scroll h-full;
  }
</style>
