import Vue from "vue";
import VueI18n from "vue-i18n";
import {configure} from "vee-validate";
import moment from 'moment'

Vue.use(VueI18n)


const availableLocales = ['en', 'fr']
const defaultLocale = 'en' // locale that will be use if the user don't select manually one in the GUI and we don't have the same one as it's browser
const fallbackLocale = 'en' // locale that will be use in case we don't have translation for selected locale

// Set the locale to moment.js for date displaying according to locale loaded
moment.locale(chooseLocale())

/**
 * Chose the locale that will be use.
 * Priorities:
 * 1. Locale stored in the local storage (localePref)
 * 2. Locale depends on the browser language
 * 3. Default locale
 * @returns {string} The chosen locale depends on priorities
 */
function chooseLocale() {
  let chosenLocale = defaultLocale
  const choice1 = localeFromLocalStorage()
  if(choice1 === null){
    const choice2 = localeFromBrowser()
    if(choice2 !== null){
      chosenLocale = choice2
    }
  }else{
    chosenLocale = choice1
  }
  return chosenLocale;
}

/**
 * Fetch the locale contained in the local storage (localePref)
 * The locale found must be available in the app
 * @returns {string|null} The locale if it is defined, null otherwise
 */
function localeFromLocalStorage() {
  const localePref = window.localStorage.getItem("localePref")
  if(localePref !== null) {
    if(availableLocales.includes(localePref.toLowerCase())){
      return localePref
    }else{
      return null
    }
  }else{
    return localePref
  }
}

/**
 * Fetch the locale regarding to the browser language.
 * The locale found must be available in the app
 * @returns {string|null} The locale if it is defined, null otherwise
 */
function localeFromBrowser() {
  // Keep only the 2 first char for the detected browser locale
  const localeBrowser = navigator.language.toLowerCase().substring(0,2)
  if(availableLocales.includes(localeBrowser)){
    return localeBrowser
  }
  return null;
}

/**
 * According all the available locales, generate all translation messages.
 * Need to have a folder named as the locale and all translation json file into it (pages, validations, fields)
 * @returns An object that contains all translation messages by available locales
 */
function generateAllTranslations() {
  let messages = {}
  for(let locale of availableLocales){
    messages[locale] = {
      "pages" : require("./" + locale + "/pages.json"),
      "validations" : require("./" + locale + "/validations.json"),
      "fields": require("./" + locale + "/fields.json"),
      "backend_trans_keys": require("./" + locale + "/backend_trans_keys.json"),
      "words": require("./" + locale + "/words.json"),
      "components": require("./" + locale + "/components.json"),
    }
  }
  return messages
}

/**
 * Configure the VeeValidate library (form validation) to use the i18n library for field and validation
 * translations.
 * https://vee-validate.logaretm.com/v3/guide/localization.html#using-other-i18n-libraries
 */
configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    return i18n.t(`validations.${values._rule_}`, values);
  }
});

const i18n = new VueI18n({
  locale: chooseLocale(),
  fallbackLocale: fallbackLocale,
  messages: generateAllTranslations()
});


export {availableLocales, i18n}
