<template>
  <div class="mt-10 text-center">
    <h1 class="text-3xl font-light">{{ $t('pages.error_4xx.title') }} {{ statusCode }} : {{ $t('pages.error_4xx.status.'+statusCode) }}</h1>
  </div>
</template>

<script>
export default {
  name: "Error4XX",
  props: {
    statusCode: {
      type: Number,
      required: false,
      default: 404
    }
  }
}
</script>

<style scoped>

</style>
