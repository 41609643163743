<template>
  <select @change="changeLocale" :class="customClass" v-model="selectedValue" class="outline-none cursor-pointer">
    <option v-for="(lang, i) in langs" :key="i" :value="lang">
      {{ lang }}
    </option>
  </select>
</template>

<script>
import {availableLocales} from "../../locales";
//import {generatePageHeadTitle} from "../../utils";
//import { localeChanged } from 'vee-validate';
import moment from "moment";

export default    {
  name: "locale-changer",
  props: {
    customClass: {
      type: String,
      required: false
    }
  },
  data: function () {
    return {
      langs: availableLocales,
      selectedValue: this.$i18n.locale
    }
  },
  methods: {
    /**
     * Change the current app locale and put it into the local storage for upcoming visit.
     * (only if the selected value is available)
     * Force the page to reload, to be able to translate all data even when data are not reactive.
     * (like in our DataTable component or in chart displaying)
     */
    changeLocale() {
      if(this.langs.includes(this.selectedValue)){
        window.localStorage.setItem('localePref', this.selectedValue)
        // Old way to instantly load new translations including headTitle and
        //this.$i18n.locale = this.selectedValue
        //document.title = generatePageHeadTitle(this.$route.name)
        //localeChanged(); // notify vee-validate of localization changes
        moment.locale(this.selectedValue) // Change the moment.js locale
        location.reload()
      }
    }
  }
}
</script>

<style scoped>

</style>
