<template>
  <div>
    <div v-if="isLoaded" class="card box-container">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(submitForm)">
          
          <!-- Note -->
          <div class="note-container">
            <p style="color: red;"><strong>{{ $t('pages.sensor_edit.note') }}</strong></p>
          </div>

          <!-- Conteneur pour les éléments à gauche (ID et Type de capteur) -->
          <div class="flex justify-between items-start">
            <div class="flex-1">
              <!-- Affichage de l'ID du capteur -->
              <div class="max-w-sm">
                <ValidationProvider :name="'Sensor ID'" rules="required|numeric|max:255|min_value:1" v-slot="{ errors }">
                  <label class="form__label">ID<Required /></label>
                  <input v-model="sensor.id" class="form__input w-full" type="text">
                  <p class="form__error">{{ errors[0] }}</p>
                  <p class="form__error" v-if="formErrors.id">{{ formErrors.id }}</p>
                </ValidationProvider>
              </div>

              <!-- Affichage du Type de capteur -->
              <div class="max-w-sm mt-8">
                <ValidationProvider :name="'Sensor Type'" rules="required" v-slot="{ errors }">
                  <label class="form__label">Type de capteur<Required /></label>
                  <select v-model="selected" class="form__input w-full">
                    <option v-for="type in sensorTypes" :key="type.id" :value="type.id">{{ $t('backend_trans_keys.' + type.transKey) }}</option>
                  </select>
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <!-- Bouton Supprimer un capteur -->
          <div class="flex justify-start">
            <button type="button" @click="toggleDeleteSensorModal" class="btn btn--danger mt-10">
              <i class="fas fa-trash"></i> {{ $t('pages.sensor_edit.delete.button') }}
            </button>
          </div>

          <!-- Bouton de validation -->
          <div class="flex justify-start">
            <button class="mt-6 btn" :class="{ 'btn--success': !invalid, 'btn--disabled': invalid }" type="submit">
              <i class="fas fa-check mr-2"></i>{{ $t('pages.sensor_edit.validationBtn') }}
            </button>
          </div>
        </form>
      </ValidationObserver>

      <!-- Modal de confirmation de suppression -->
      <Modal :visible="modalDeleteSensorVisible" :toggle="toggleDeleteSensorModal">
        <h2 class="text-lg mb-2">{{ $t('pages.sensor_edit.delete.modal-title') }}</h2>
        <div>{{ $t('pages.sensor_edit.delete.modal-text') }}</div>
        <div class="flex justify-between w-full">
          <button @click="toggleDeleteSensorModal" class="mt-6 btn btn--danger">{{ $t('pages.sensor_edit.delete.cancel') }}</button>
          <button @click="deleteSensor" class="mt-6 btn btn--success">{{ $t('pages.sensor_edit.delete.confirm') }}</button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Required from "../../../components/forms/Required";
import Modal from "../../../components/modals/modal.vue";
import { 
  API_SENSORS_SHOW2, 
  API_SENSORS_UPDATE, 
  API_SENSORS_DELETE, 
  API_SENSOR_TYPES_ALL 
} from "../../../utils/constants";
import { apiGetRequest, apiPostRequest, displayAlertSuccess, errorResponseIsFormError } from "../../../utils";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "SensorEdit",
  components: { 
    ValidationObserver,
    ValidationProvider,
    Required,
    Modal
  }, 
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      isLoaded: false,
      form: {
        sensorTypeId: null,
        id: null,
      },
      sensor: {
        id: null,
        typeid: null,
        sensorTypeId: null,
      },
      sensorTypes: [],
      selected: null,        
      modalDeleteSensorVisible: false,
      formErrors: {} 
    };
  },
  mounted() {
    this.loadData(); 
  },
  methods: {
    loadData() {
      this.isLoaded = false;

      Promise.all([
        apiGetRequest(API_SENSORS_SHOW2(this.id)), 
        apiGetRequest(API_SENSOR_TYPES_ALL) 
      ])
      .then((res) => {
        console.log("Reponse de l'API au capteur:", res[0].data);
        this.sensor = res[0].data; 
        const allSensorTypes = res[1].data; 

        // Filtrer les types de capteurs pour exclure ceux liés à la station météo
        this.sensorTypes = allSensorTypes.filter(st => !st.solderedToTransmitter);
        this.selected = this.sensorTypes.filter(tr => (tr.transKey == this.sensor.typeTransKey))[0].id;

        // Charger les autres champs nécessaires
        this.form.id = this.sensor.id; 
        this.isLoaded = true; 
        console.log("Données du capteur chargées avec succès:", this.sensor);
      })
      .catch((error) => {
        console.error("Erreur lors du chargement des données", error);
      });
    },
    submitForm() {
      if (this.selected == null) {
        console.error("Aucun type de capteur sélectionné !");
        return;
      }

      this.form.id = this.sensor.id;
      this.form.sensorTypeId = this.selected;

      const formData = new FormData();
      formData.append('id', this.form.id);
      formData.append('sensorTypeId', this.form.sensorTypeId);

      apiPostRequest(API_SENSORS_UPDATE(this.id), formData, true)
        .then(() => {
          const successMsg = this.$t('pages.sensor_edit.success', { name: this.form.name });
          displayAlertSuccess(successMsg);
          this.$router.push({ name: 'inventory' }); 
          console.log("Mise à jour du capteur réussie");
        })
        .catch((e) => {
          this.formErrors = {}; // Réinitialiser les erreurs à chaque soumission
          if (errorResponseIsFormError(e) && e.response.data.fields) {
            const uniqueErrorLabel = this.$t('pages.inventory.addMaterialModal.forms.uniqueError');

            // Vérifier l'erreur d'unicité de l'ID et l'injecter sous le champ ID
            if (e.response.data.fields.id === 'unique') {
              this.formErrors.id = uniqueErrorLabel; 
            }
          } else {
            this.toggle();
          }
        });
    },
    deleteSensor() {
      console.log("Début de la suppression du capteur avec ID", this.id);

      apiPostRequest(API_SENSORS_DELETE(this.id))
        .then(() => {
          console.log("Requête de suppression envoyé avec succès");
          displayAlertSuccess(this.$t('pages.sensor_edit.delete.success'));
          console.log("Redirection vers l'inventaire");
          this.$router.push({ name: 'inventory' }); 
          console.log("Suppression du capteur réussie");
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression du capteur", error);
        });
    },
    toggleModal(){
      this.modalVisible = !this.modalVisible;
      displayAlertSuccess(this.$t('pages.sensor_edit.'))
    },
    toggleDeleteSensorModal(){
      this.modalDeleteSensorVisible = !this.modalDeleteSensorVisible;
    },
  }
};
</script>

<style scoped>
.box-container {
  width: 100%;
  border: none;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  overflow: auto;
}
</style>