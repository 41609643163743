<template>
  <div>
    {{ info.from }}-{{ info.to }} {{ $t('components.datatable.table-info.of') }} {{ info.of }}
  </div>
</template>

<script>
export default {
  name: "table-info",
  props: {
    totalRows: {
      type: Number,
      required: true
    },
    activePage: {
      type: Number,
      required: true
    },
    limitPerPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    /**
     * Calculate indexes (from, to, of) accoring the total rows, active page and limit per page
     * @returns {{of: number, from: number, to: number}}
     */
    info: function() {
      const rawStart = (this.limitPerPage * this.activePage) - (this.limitPerPage - 1)
      const rawEnd = this.limitPerPage * this.activePage

      return {
        from: this.totalRows >= 1 ? rawStart : 0,
        to: rawEnd <= this.totalRows ? rawEnd : this.totalRows,
        of: this.totalRows
      }
    }
  }
}
</script>

<style scoped>

</style>
