<template>
  <div>
    <!-- Carte de détails du capteur -->
    <div v-if="isSensorLoaded" class="card sensor-card">
      <h2 class="card__title">
        {{ $t('pages.sensor_show.cards.details.title') }}
        <span class="font-semibold">"{{ sensor.id }}"</span>
      </h2>

      <!-- Conteneur avec bordure autour des informations -->
      <div class="sensor-info-box">
        <div class="flex justify-start">
          <!-- Section des étiquettes et des icônes -->
          <div class="info-labels w-1/3 text-gray-600">
            <div>
              <i class="fas fa-signal mr-2"></i>{{ $t('pages.sensor_show.cards.details.type') }}:
            </div>
            <div>
              <i class="fas fa-broadcast-tower mr-2"></i>{{ $t('pages.sensor_show.cards.details.transmitter') }}:
            </div>
            <div>
              <i class="fas fa-leaf mr-2"></i>{{ $t('pages.sensor_show.cards.details.culture') }}:
            </div>
          </div>

          <!-- Section des valeurs des informations -->
          <div class="info-values ml-4 font-semibold">
            <div class="info-value">{{ $t('backend_trans_keys.' + sensor.typeTransKey) }}</div>
            <div class="info-value">
              {{ sensor.transmitter === null ? $t('pages.sensor_show.cards.details.noneM') : `${sensor.transmitter.id} (${$t('backend_trans_keys.' + sensor.transmitter.typeTransKey)})` }}
            </div>
            <div class="info-value">
              {{ sensor.culture === null ? $t('pages.sensor_show.cards.details.noneF') : `${sensor.culture.name} (${$t('backend_trans_keys.' + sensor.culture.typeTransKey)} ${$t('backend_trans_keys.' + sensor.culture.varietyTransKey)})` }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Boutons pour rafraîchir et télécharger le CSV -->
    <div v-if="isSensorLoaded && sensor.culture !== null" class="mt-4 button-group">
      <button v-if="selectedTimePeriod !== 5" @click="refreshCharts" class="mr-4 btn btn--primary">
        <i class="fas fa-sync" :class="{ 'fa-spin': loadingMeasures }"></i>
      </button>
      <btn-with-loader :loading="loadingCSV" :class-btn-type="'btn--primary'" @clicked="downloadCsv">
        <i class="fas fa-download mr-2"></i>CSV
      </btn-with-loader>
    </div>

    <!-- Sélection de période de temps et graphiques de mesures -->
    <div v-if="isMeasuresLoaded">
      <select-time-period
        :default-value="selectedTimePeriod"
        :default-show-data-picker="showDatePicker"
        :default-start-date="startDate"
        :default-end-date="endDate"
        @valueChanged="timePeriodChanged"
        @submitCustomDate="submitCustomPeriod"
        class="mt-3"
      />

      <div class="measure-types-container">
        <div v-for="measureType in measureTypes" :key="measureType.id" class="card mt-5">
          <div class="overflow-x-auto">
            <div style="min-width: 600px">
              <highcharts :options="measureType.chartOptions" ref="lineCharts" constructor-type="chart" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {apiGetRequest, displayAlertError, generateDownloadCsvLink} from "../../../utils";
import {
  API_SENSORS_MEASURES_CSVEXPORT_SUFFIX,
  API_SENSORS_MEASURES_SUFFIX,
  API_SENSORS_SHOW
} from "../../../utils/constants";
import {Chart} from 'highcharts-vue'
import SelectTimePeriod from "../../../components/charts/SelectTimePeriod";
import {getFromAndToQueryParam, getFromQueryParam, getLineChartOptionsByMeasureType} from "../../../utils/chartUtils";
import btnWithLoader from "../../../components/general/btn-with-loader";


export default {
  name: "SensorShow",
  components: {
    SelectTimePeriod,
    highcharts: Chart,
    btnWithLoader: btnWithLoader
  },
  data: function() {
    return {
      id: this.$route.params.id,
      loadingCSV: false,
      isSensorLoaded: false,
      isMeasuresLoaded: false,
      loadingMeasures: false,
      sensor : {},
      measureTypes : {},
      selectedTimePeriod: 1,
      showDatePicker: false,
      startDate:"",
      endDate:"",
    }
  },
  mounted() {
    this.isSensorLoaded = false
    this.isMeasuresLoaded = false

    apiGetRequest(API_SENSORS_SHOW + this.id)
        .then((res) => {
          this.sensor = res.data
          this.isSensorLoaded = true
          // Load the charts only if the sensor is in a culture (so have a transmitter linked)
          if(this.sensor.culture !== null){
            this.refreshCharts()
          }
        })
  },
  methods: {
    timePeriodChanged: function(selectedTimePeriod){
      this.selectedTimePeriod = selectedTimePeriod
      if(selectedTimePeriod !== 5){
        this.refreshCharts()
        this.showDatePicker = false
      }else{
        this.showDatePicker = true
      }
    },
    submitCustomPeriod: function(rangeDates){
      this.startDate = rangeDates.start
      this.endDate = rangeDates.end
      const fromAndToQueryParams = getFromAndToQueryParam(rangeDates)
      this.refreshCharts2(fromAndToQueryParams)

    },

    refreshCharts: function() {
      const fromQueryParam = getFromQueryParam(this.selectedTimePeriod)
      this.refreshCharts2(fromQueryParam)
    },
    /**
     * Ask the API to fetch all measures according the selected time period, and display all charts.
     * We fetch each time the whole wanted data, not only the new one (if we refresh multiple time)
     * todo later : fetch only new data, according the last loaded value
     */
    refreshCharts2 : function(queryParams){
      if(!this.loadingMeasures) {
        this.isMeasuresLoaded = false
        this.measureTypes = {}
        this.loadingMeasures = true
        apiGetRequest(API_SENSORS_SHOW + this.id + API_SENSORS_MEASURES_SUFFIX + queryParams)
            .then((res) => {
              this.updateMeasureTypes(res.data)
              this.isMeasuresLoaded = true
            })
            .finally(() => this.loadingMeasures = false)
      }
    },
    /**
     * Update (extends) the local measureTypes variable to add all measures data provided by the API.
     * The updated value will contain all data and options for the chart displaying
     * @param data All measures data provided by the API
     */
    updateMeasureTypes: function(data){
      this.measureTypes = data

      for (const key in this.measureTypes) {
        const measureType = this.measureTypes[key]
        measureType["chartOptions"] = getLineChartOptionsByMeasureType(measureType, () => {
          // Error may be possible due to old browser version
          const errorMsg = this.$t('pages.sensor_show.error-export')
          displayAlertError(errorMsg)
        })
      }
    },
    /**
     * Asynchronous call to generate csv export that contains all measures for this sensor
     * For ajax call, we need to recreate a link and simulate a click to download the real file.
     */
    downloadCsv: function() {
      if(!this.loadingCSV){
        this.loadingCSV = true
        apiGetRequest(API_SENSORS_SHOW + this.id + API_SENSORS_MEASURES_CSVEXPORT_SUFFIX)
            .then((res) => {
              const link = generateDownloadCsvLink(res.data, res.headers['x-filename'])
              link.click();
            })
            .finally(() => this.loadingCSV = false)
      }
    }
  }
}
</script>


<style scoped>
.sensor-card {
  margin: 20px auto; /* Centrer la carte avec un espace autour */
  background-color: #ffffff; /* Fond blanc pour plus de clarté */
  border: none; /* Retire la bordure grise */
  border-radius: 12px; /* Coins plus arrondis */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Ombre */
  padding: 20px; /* Plus d'espace interne */
  transition: transform 0.3s; 
}

.card__title {
  color: #333; /* Couleur plus foncée pour le texte */
  font-size: 1.6rem; /* Taille de police plus cohérente */
  margin-bottom: 16px;
  border-bottom: 2px solid #2d2f31; /* Sous-ligne pour le titre */
  padding-bottom: 8px; /* Espacement sous le titre */
  text-decoration: none;
}

.sensor-info-box {
  background-color: #f9f9f9; /* Fond gris clair pour contraste */
  border-radius: 8px;      
  padding: 16px;
  margin-top: 16px; /* Espace plus généreux */
}

.info-labels div,
.info-values .info-value {
  margin-bottom: 10px; /* Espacement plus cohérent */
  font-size: 1.2rem; /* Réduire légèrement la taille pour l'harmonie */
  color: #555; /* Couleur grise pour le texte */
}

.button-group {
  display: flex;
  justify-content: flex-start;
  gap: 15px; /* Espacement plus important entre les boutons */
}

.btn {
  padding: 10px 15px; /* Espacement interne des boutons */
  font-size: 1rem; /* Taille de police des boutons */
  border-radius: 5px; /* Coins arrondis pour les boutons */
  transition: background-color 0.3s; /* Transition pour changement de couleur */
}

.btn--primary {
  background-color: #0963e9; 
  color: white; /* Couleur du texte */
}

.btn--primary:hover {
  background-color: #084aaf; /* Couleur au survol */
}

.measure-types-container {
  display: flex;
  flex-direction: column;
  gap: 20px; 
}
</style>
