<template>
  <div class="page-container"> 
    <div v-if="isLoaded" class="card box-container"> 
      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="isWeatherStation ? null : handleSubmit(submitForm)">
          <!-- Note -->
          <div class="note-container">
            <p style="color: red;"><strong>{{ $t('pages.transmitter_edit.note') }}</strong></p>
          </div>

          <div class="flex justify-between items-start mt-4">
            <div class="flex-1 w-full"> 
              <!-- ID de l'émetteur -->
              <div class="max-w-sm">
                <ValidationProvider name="Transmitter ID" rules="required|numeric" v-slot="{ errors }">
                  <label class="form__label">ID<Required /></label>
                  <input v-model="transmitter.id" class="form__input w-full" type="text" :disabled="isWeatherStation">
                  <p class="form__error">{{ errors[0] }}</p>
                  <p class="form__error" v-if="formErrors.id">{{ formErrors.id }}</p>
                </ValidationProvider>
              </div>

              <!-- TTN-ID de l'émetteur -->
              <div class="max-w-sm mt-8">
                <ValidationProvider name="TTN ID" rules="required" v-slot="{ errors }">
                  <label class="form__label">TTN-id<Required /></label>
                  <input v-model="ttnId" class="form__input w-full" type="text" :disabled="isWeatherStation">
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <!-- Type de l'émetteur -->
              <div class="max-w-sm mt-8">
                <ValidationProvider name="Type transmitter" rules="required" v-slot="{ errors }">
                  <label class="form__label">Type d'émetteur<Required /></label>
                  <select v-model="selected" class="form__input w-full" :disabled="isWeatherStation">
                    <option v-for="type in transmitterTypes" :key="type.id" :value="type.id">
                      {{ $t('backend_trans_keys.' + type.transKey) }}
                    </option>
                  </select>
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>

            <!-- Note TTN-ID -->
            <div class="noteTTNid-container mt-5" style="flex-shrink: 0; text-align: right;"> 
              <p style="color: red;"><strong>{{ $t('pages.transmitter_edit.noteTTN') }}</strong></p>
            </div>
          </div>

          <!-- Affichage des capteurs pour la station météo (entre le type d'émetteur et le bouton "Supprimer") -->
          <div v-if="transmitter.sensors && transmitter.sensors.length > 0" class="card mt-4 w-full">
            <!-- Conteneur avec la bordure autour du titre et des capteurs -->
            <div class="bordered-sensor-container w-full">
              <h2 class="card__title titre-capteurs">
                {{ $t('pages.transmitter_edit.WeatherSensors') }}
              </h2>
              <div class="sensor-list">
                <div v-for="sensor in transmitter.sensors" :key="sensor.id" class="sensor-item mr-4 mb-2 p-2 border rounded">
                  <span class="font-semibold">{{ sensor.id }}</span> - {{ sensor.type }}
                </div>
              </div>
            </div>
          </div>

          <!-- Bouton Supprimer un émetteur -->
          <div class="mt-14">
            <button type="button" @click="toggleDeleteTransmitterModal" class="btn btn--danger">
              <i class="fas fa-trash"></i> {{ $t('pages.transmitter_edit.delete.button') }}
            </button>
          </div>

          <!-- Bouton de validation -->
          <button 
            @click="isWeatherStation ? null : submitForm" 
            class="mt-6 btn" 
            :class="{ 'btn--success': !invalid, 'btn--disabled': invalid || isWeatherStation }" 
            type="submit"
            :disabled="invalid || isWeatherStation">
            <i class="fas fa-check mr-2"></i>{{ $t('pages.transmitter_edit.validationBtn') }}
          </button>
        </form>
      </ValidationObserver>

      <!-- Modal pour confirmer la suppression -->
      <Modal :visible="modalDeleteTransmitterVisible" :toggle="toggleDeleteTransmitterModal">
        <h2 class="text-lg mb-2">{{ $t('pages.transmitter_edit.delete.modal-title') }}</h2>
        <div>{{ $t('pages.transmitter_edit.delete.modal-text') }}</div>
        <div class="flex justify-between w-full">
          <button @click="toggleDeleteTransmitterModal" class="mt-6 btn btn--danger">{{ $t('pages.transmitter_edit.delete.cancel') }}</button>
          <button @click="deleteTransmitter" class="mt-6 btn btn--success">{{ $t('pages.transmitter_edit.delete.confirm') }}</button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Required from "../../../components/forms/Required";
import Modal from "@/components/modals/modal.vue"; 
import { 
  API_TRANSMITTERS_SHOW2, 
  API_TRANSMITTERS_UPDATE, 
  API_TRANSMITTERS_DELETE, 
  API_TRANSMITTER_TYPES_ALL 
} from "../../../utils/constants";
import { apiGetRequest, apiPostRequest, displayAlertSuccess, errorResponseIsFormError } from "../../../utils";
import { ValidationProvider } from "vee-validate"; 

export default {
  name: "TransmitterEdit",
  components: { Required, Modal, ValidationProvider }, 
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      isLoaded: false,
      transmitter: null,
      transmitterTypes: null,
      modalDeleteTransmitterVisible: false,
      isAdmin: this.$store.getters.userIsAdmin, 
      sensorTable: {
        columns: {
          id: this.$t('words.id'),
          type: this.$t('words.type')
        }
      },
      ttnId: "", 
      selected: null,
      isWeatherStation: false,
      form: {
        id: null,
        ttnId: "",
        transmitterTypes: null,
      }, 
      formErrors: {}
    };
  },
  mounted() {
    this.loadData(); 
  },
  methods: {
    loadData() {
      this.isLoaded = false;

      Promise.all([
        apiGetRequest(API_TRANSMITTERS_SHOW2(this.id)),
        apiGetRequest(API_TRANSMITTER_TYPES_ALL)
      ])
      .then((res) => {
        this.transmitter = res[0].data;
        this.transmitterTypes = res[1].data;
        this.selected = this.transmitterTypes.find(tr => tr.transKey === this.transmitter.typeTransKey)?.id || null;
        this.ttnId = "id-" + this.transmitter.id;
        this.transmitter.sensors = this.transmitter.sensors.map((sensor) => ({
          ...sensor,
          type: this.$t('backend_trans_keys.' + sensor.typeTransKey)
        }));
        this.isWeatherStation = this.transmitterTypes.find(tr => tr.transKey === this.transmitter.typeTransKey)?.id != 1
        this.isLoaded = true;
        
      })

      .catch((error) => {
        console.error(error);  
      });
    },

    submitForm() {
      this.form.id = this.transmitter.id;
      this.form.ttnId = this.transmitter.ttnId;
      this.form.transmitterTypes = this.selected; 

      const formData = new FormData();
      formData.append('id', this.transmitter.id);
      formData.append('ttnId', this.ttnId);
      formData.append('transmitterTypeId', this.form.transmitterTypes);
      
      apiPostRequest(API_TRANSMITTERS_UPDATE(this.id), formData, true)
        .then(() => {
          const successMsg = this.$t('pages.transmitter_edit.success', { name: this.form.name });
          displayAlertSuccess(successMsg);
          this.$router.replace({ name: 'inventory' }); 
          console.log("Mise à jour de l'émetteur");
        })
        .catch((e) => {
          this.formErrors = {};
          if (errorResponseIsFormError(e) && e.response.data.fields){
            const uniqueErrorLabel = this.$t('pages.inventory.addMaterialModal.forms.uniqueError');

            if(e.response.data.fields.id === 'unique'){
              this.formErrors.id = uniqueErrorLabel;
            }
          } else{
            this.toggle();
          }         
        });
    },

    deleteTransmitter() {
      console.log("Requête de la suppression de l'émetteur avec ID", this.id);

      apiPostRequest(API_TRANSMITTERS_DELETE(this.id), null)
        .then(() => {
          console.log("Requête de suppression envoyée avec succès");
          displayAlertSuccess(this.$t('pages.transmitter_edit.delete.success'));
          this.$router.push({ name: 'inventory' }); 
          console.log("Suppression de l'émetteur réussie");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toggleModal(){
      this.modalVisible = !this.modalVisible;
      displayAlertSuccess(this.$t('pages.transmitter_edit'))
    },
    toggleDeleteTransmitterModal() {
      this.modalDeleteTransmitterVisible = !this.modalDeleteTransmitterVisible;
    },
  }
};
</script>

<style scoped>
.box-container {
  width: 100%;
  border: none;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  overflow: auto;
}

.note-container {
  margin-left: 30px;
  margin-bottom: 20px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 62px;
}

.noteTTNid-container {
  margin-left: 30px; 
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.edit-form {
  display: flex;
  flex-direction: column;
}
.sensor-list {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 15px; /*Esapce entre le titre et les capteurs */
}

.card_title {
  font-weight: 500;
  text-decoration: none;
}

.sensor-item {
  background-color: #f0f0f0;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid rgb(150, 141, 141); /* Bordure autour de chaque capteur */
}

.card__title{
  text-decoration: none;
  font-weight: 500;
}

.titre-capteurs {
  text-align: left;
  padding: 0 10px 10px 10px;
  margin: 0;
}

.sensor-card {
  background-color: #f8f9fa;
  padding: 10px;
  margin: 8px 0;
  border-radius: 4px;
  border: 1px solid #d1d5db;
}

.bordered-sensor-container {
  border: 2px solid #a4a8a4;  /* Bordure autour du titre et des capteurs */
  border-radius: 8px; /* Coins arrondis */
  padding: 10px; 
  background-color: #ffffff;  /* Fond de la section */
  width: 41%; /* Largeur*/
  margin-top: 15px;
  margin-bottom: -10px;
  margin-left: -15px;
}
</style>