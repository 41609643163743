<template>
  <div>
    <div class="card">
      <h2 class="card__title">{{$t('pages.user_create.head-title')}}</h2>
      <UserForm :on-submit="submitUserForm" :errors-form="errorsForm"/>
    </div>
    <modal :visible="modalVisible" :toggle="toggleModal">
      <h2 class="text-lg mb-2">{{$t("pages.user_create.success")}}</h2>
      <div>{{$t('pages.user_create.copy-password')}}</div>
      <h1 class="text-xl text-center font-bold m-5">{{newUserPassword}}</h1>
      <div class="text-center">
        <button @click="toggleModal" class="mt-6 btn btn--success">{{ $t('pages.user_create.modal-ok-password') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {apiPostRequest, displayAlertSuccess, errorResponseIsFormError} from "@/utils";
import {API_USERS_CREATE} from "@/utils/constants";
import Modal from "@/components/modals/modal.vue";
import UserForm from "@/views/app/user/UserForm.vue";

export default {
  name: "UserCreate",
  components: {UserForm, Modal},
  data:function(){
    return {
      errorsForm:{},
      modalVisible:false,
      newUserPassword:"",
    }
  },
  methods:{
    submitUserForm:function(data){
      if (this.$store.getters.userIsAdmin) {
        apiPostRequest(API_USERS_CREATE, data)
            .then((res) => {
              this.newUserPassword = res.data;
              this.modalVisible = !this.modalVisible;
              displayAlertSuccess(this.$t("pages.user_create.success"))
            })
            .catch(e => {
              if (errorResponseIsFormError(e)) {
                const errors = {}
                for (const errorKey in e.response.data.fields) {
                  if (e.response.data.fields[errorKey] === 'unique') {
                    switch (errorKey) {
                      case "email":
                        errors[this.$t('words.email')] = this.$t('pages.user_create.error.emailUnique')
                        break;
                    }
                  }
                }
                this.errorsForm = errors;
              }
            })
      }
    },
    toggleModal:function(){
      this.$router.push({name: "user_index"})
    }
  }
}
</script>

<style scoped>

</style>