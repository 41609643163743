<template>
  <div>
    <!-- New culture btn -->
    <router-link :to="{ name: 'culture_create', params: { farmerId: this.$store.state.user.id } }">
      <button class="btn btn--success">
        <i class="fas fa-plus mr-2"></i>{{ $t('pages.culture_index.new-culture') }}
      </button>
    </router-link>

    <!-- All cultures -->
    <div v-if="isLoaded" class="mt-4">
      <div class="card">
        <h2 class="card__title">
          {{ $t('pages.culture_index.cards.cultures.title', { 'count': culturesTable.data.length }) }}
        </h2>
        <div class="culture-grid">
          <div v-for="culture in culturesTable.data" :key="culture.id" class="culture-card">
            <!-- Icône d'agriculture en haut à gauche -->
            <i class="fas fa-seedling agriculture-icon"></i>
            <div class="culture-name">{{ culture.name }}</div>
            <div class="culture-info-title">{{ $t('pages.culture_index.cards.cultures.infoTitle') }}</div>
            <div class="culture-details">
              <div class="culture-row">
                <div class="detail-title">{{ culturesTable.columns.cultivatedVariety }}:</div>
                <div class="detail-value">{{ culture.cultivatedVariety }}</div>
              </div>
              <div class="culture-row">
                <div class="detail-title">{{ culturesTable.columns.cultureType }}:</div>
                <div class="detail-value">{{ culture.cultureType }}</div>
              </div>
              <div class="culture-row">
                <div class="detail-title">{{ culturesTable.columns.nouaisonDate }}:</div>
                <div class="detail-value">{{ culture.nouaisonDate }}</div>
              </div>

              <!-- Titre pour l'Etat de la culture -- Message pour indiquer les conditions climatiques en fonction des mesures -->
              <div class="culture-info-title">{{ $t('pages.culture_index.cards.cultures.stateTitle') }}</div>
              <!-- Affichage du/des message(s) -->
              <div class="stateMessage">
                {{ $t('pages.culture_index.cards.cultures.stateMessage') }}
              </div>
              <!-- Titre pour l'AVERTISSEMENT -- Message pour les problèmes matériel (pince dendro décrochée, etc...) -->
              <div class="warning-title">{{ $t('pages.culture_index.cards.cultures.messageTitle') }}</div>
              <!-- Message pour l'avertissement -->
              <div class="warning-message">
                {{ $t('pages.culture_index.cards.cultures.warningFruitClip') }}
              </div>
              <div class="culture-actions">
                <router-link :to="culture.actions.see.to" class="see-btn">{{ culture.actions.see.text }}</router-link>
                <router-link :to="culture.actions.edit.to" class="edit-btn">{{ culture.actions.edit.text }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetRequest, displayDateByLocale } from "../../../utils";
import { API_CULTURES_INDEX } from "../../../utils/constants";

export default {
  name: "CultureIndex",
  data() {
    return {
      isLoaded: false,
      culturesTable: {
        columns: {
          name: this.$t('words.name'),
          cultivatedVariety: this.$t('pages.culture_index.cards.cultures.columns.cultivatedVariety'),
          cultureType: this.$t('pages.culture_index.cards.cultures.columns.cultureType'),
          nouaisonDate: this.$t('pages.culture_index.cards.cultures.columns.nouaisonDate'),
          nbTransmitters: this.$t('words.nbTransmitters'),
          actions: this.$t('words.actions'),
        },
        data: null
      }
    };
  },
  mounted() {
    this.loadCultures();
  },
  methods: {
    loadCultures() {
      this.isLoaded = false;
      apiGetRequest(API_CULTURES_INDEX)
        .then((res) => {
          this.culturesTable.data = res.data.map((c) => {
            c.cultivatedVariety = `${this.$t('backend_trans_keys.' + c.typeTransKey)} (${this.$t('backend_trans_keys.' + c.varietyTransKey)})`;
            c.cultureType = c.outside ? this.$t('words.outside') : this.$t('words.inside');
            c.nouaisonDate = c.nouaisonDate === null ? '-' : this.displayDate(c.nouaisonDate);
            c.actions = {
              see: {
                to: { name: 'culture_show', params: { id: c.id } },
                text: this.$t('words.see')
              },
              edit: {
                to: { name: 'culture_edit', params: { id: c.id } },
                text: this.$t('words.edit')
              }
            };
            return c;
          });

          this.isLoaded = true;
        });
    },
    displayDate(date) {
      return displayDateByLocale(date, true);
    },
    getCultureImage(type) {
      const images = {
        pomme: 'path/to/apple-icon.png',
        tomate: 'path/to/tomato-icon.png',
        poire: 'path/to/pear-icon.png'
      };
      return images[type] || 'path/to/default-icon.png';
    }
  }
};
</script>

<style scoped>
.culture-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ajuste la largeur des cartes automatiquement */
  gap: 16px;
}

.culture-card {
  position: relative; /* Position pour l'icône d'agriculture */
  box-sizing: border-box;
  border: 2px solid #ddd;
  border-radius: 12px;
  padding: 16px;
  background: linear-gradient(135deg, #f9f9f9, #e0e0e0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left;
}

@media (min-width: 768px) {
  .culture-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.agriculture-icon {
  font-size: 24px;
  color: #4CAF50; 
  position: absolute;
  top: 16px;
  left: 16px;
}

.culture-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 10px;
}

.culture-name {
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.last-info-row {
  margin-bottom: 20px; 
}

.culture-info-title,
.warning-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 20px 0 10px 0; /* Ajuste la marge supérieure pour créer plus d'espace */
  border-bottom: 1px solid #ddd; /* Ligne en dessous */
  padding-bottom: 5px; /* Espacement sous la ligne */
}

.warning-title {
  font-size: 1.4em; /* Taille du texte pour le titre d'importance */
  color: #d3100a; /* Couleur rouge pour l'avertissement */
}

.warning-message {
  font-size: 1.1em; /* Taille du texte pour le message d'avertissement */
  color: #333; /* Couleur du texte du message */
  margin-bottom: 20px; /* Ajuste la marge inférieure pour créer plus d'espace avec le contenu suivant */
  padding: 10px; /* Espacement autour du texte */
  background-color: #f9f9f9; /* Couleur de fond pour mettre en évidence le message */
  border: 1px solid #ddd; /* Bordure légère pour séparer le message */
  border-radius: 4px; /* Coins arrondis */
}

.culture-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.culture-row {
  display: flex;
  justify-content: space-between;
}

.detail-title {
  font-weight: bold;
}

.culture-actions {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.culture-actions a {
  display: inline-block;
  padding: 10px 16px;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  color: #f7f7f7;             /* Couleur du texte */
  background-color: #f7e0a2;  /* Couleur de fond */
  border: 2px solid #173a2c;  /* Couleur de la bordure */
  transition: background-color 0.3s, transform 0.2s;
}

.culture-actions a:hover {
  background-color: #e5f1e8;  /* Couleur de fond en survol */
  border-color: #8ca090;      /* Couleur de la bordure en survol */
  transform: scale(1.1);
}
</style>