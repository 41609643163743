<template>
  <span class="text-red-500"> *</span>
</template>

<script>
export default {
  name: "Required"
}
</script>

<style scoped>

</style>
