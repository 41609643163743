<template>
  <div>
    <div class="card card--password">
      <div class="card__title">{{$t('pages.profile.update-password')}}</div>
      <div class="flex flex-wrap justify-center">
        <ChangePassword class="w-1/2" :user-id="this.$store.state.user.id"/>
      </div>
    </div>
    <div class="card card--profile mt-4">
      <div class="flex flex-wrap justify-center">
        <UserUpdate :profile="true" :title="$t('pages.profile.update-info').toString()"/>
      </div>
    </div>
  </div>
</template>

<script>
import ChangePassword from "@/components/forms/ChangePassword.vue";
import UserUpdate from "@/views/app/user/UserUpdate.vue";

export default {
  name: "Profile",
  components: { UserUpdate, ChangePassword }
}
</script>

<style scoped>
.card {
  border-radius: 8px; /* Arrondir les coins de la section */
  border: 1px solid #bbaaaa; /* Bordure fine et sombre */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ajouter une légère ombre */
  padding: 22px; /* Ajouter un peu d'espace intérieur */
  background-color: white; /* Arrière plan */
}

.card--password .card__title {
  font-size: 17px; /* Taille du titre pour la section Modifier le mot de passe */
}

.card--profile .card__title {
  font-size: 17px; /* Taille du titre pour la section Modifier le profil */
}

.card__title {
  font-weight: bold;
  margin-bottom: 12px;
}
</style>