<template>
  <modal :visible="visible" :toggle="toggle">
    <!-- Choose setup you want -->

    <h1 class="text-center font-semibold mb-2">{{ $t('pages.history_seriesEdit.modals.move.title') }}</h1>
    <div>
      <input type="radio" id="setup-actual" value="1" v-model.number="userChoices.isActualSetup" @change="onChangeSetup">
      <label for="setup-actual"> {{ $t('pages.history_seriesEdit.modals.move.setup.actual') }}</label>
      <p class="italic text-md">{{ $t('pages.history_seriesEdit.modals.move.setup.actual-info') }}</p>

      <input class="mt-2" type="radio" id="setup-historic" value="0" v-model.number="userChoices.isActualSetup" @change="onChangeSetup">
      <label for="setup-historic"> {{ $t('pages.history_seriesEdit.modals.move.setup.historic') }}</label>
      <p class="italic text-md">{{ $t('pages.history_seriesEdit.modals.move.setup.historic-info') }}</p>
    </div>

    <div v-if="setupSelected" class="mt-6">

      <!-- Admin : Choose farmer you want -->
      <div v-if="$store.getters.userIsAdmin" class="mb-2">
        <div v-if="!isLoaded.farmers" class="text-center">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else>
          <span>{{ $t('pages.history_seriesEdit.modals.move.select.farmer') }}: </span>
          <select v-model.number="userChoices.farmerId" @change="loadCultures" class="select-basic">
            <option value="-1" disabled>{{ $t('pages.history_seriesEdit.modals.move.choose') }}</option>
            <option v-for="farmer in farmers" :key="farmer.id" :value="farmer.id">{{ farmer.firstname }} {{ farmer.lastname }}</option>
          </select>
        </div>
      </div>

      <!-- Choose culture you want -->
      <div v-if="userChoices.farmerId !== -1">
        <div v-if="!isLoaded.cultures" class="text-center">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else>
          <span>{{ $t('pages.history_seriesEdit.modals.move.select.culture') }}: </span>
          <select v-if="cultures.length > 0" v-model.number="userChoices.cultureId" @change="loadTransmitters" class="select-basic" >
            <option value="-1" disabled>{{ $t('pages.history_seriesEdit.modals.move.choose') }}</option>
            <option v-for="(culture) in cultures" :key="culture.id" :value="culture.id">
              <!-- Difference between archived or not -->
              <span>{{ culture.name }}</span> <span v-if="culture.archived">{{ $t('pages.history_seriesEdit.modals.move.archivedAt', {date: displayDateTimeArrayByLocale(culture.archivedAt)}) }}</span>
            </option>
          </select>
          <span v-else class="italic">{{ $t('pages.history_seriesEdit.modals.move.no-data') }}</span>
        </div>
      </div>

      <!-- Chose transmitter you want-->
      <div v-if="userChoices.cultureId !== -1" class="mt-2">
        <div v-if="!isLoaded.transmitters" class="text-center">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else>
          <span>{{ $t('pages.history_seriesEdit.modals.move.select.transmitter') }}: </span>
          <select v-if="transmitters.length > 0" v-model.number="userChoices.transmitterId" @change="loadSensors" class="select-basic" >
            <option value="-1" disabled>{{ $t('pages.history_seriesEdit.modals.move.choose') }}</option>
            <option v-for="(transmitter) in transmitters" :key="transmitter.id" :value="transmitter.id">{{ transmitter.id }} ({{ $t('backend_trans_keys.'+transmitter.typeTransKey) }})</option>
          </select>
          <span v-else class="italic">{{ $t('pages.history_seriesEdit.modals.move.no-data') }}</span>
        </div>
      </div>

      <!-- Chose Sensor you want -->
      <div v-if="userChoices.transmitterId !== -1" class="mt-2">
        <div v-if="!isLoaded.sensors" class="text-center">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else>
          <span>{{ $t('pages.history_seriesEdit.modals.move.select.sensor') }}: </span>
          <select v-if="sensors.length > 0" v-model.number="userChoices.sensorId" @change="loadMeasurementSeries" class="select-basic" >
            <option value="-1" disabled>{{ $t('pages.history_seriesEdit.modals.move.choose') }}</option>
            <option v-for="sensor in sensors" :key="sensor.id" :value="sensor.id">{{ sensor.id }} ({{ $t('backend_trans_keys.'+sensor.typeTransKey) }})</option>
          </select>
          <span v-else class="italic">{{ $t('pages.history_seriesEdit.modals.move.no-data') }}</span>
        </div>
      </div>

      <!-- Chose measurement series you want -->
      <div v-if="userChoices.sensorId !== -1" class="mt-2">
        <div v-if="!isLoaded.measurementSeries" class="text-center">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else>
          <span>{{ $t('pages.history_seriesEdit.modals.move.select.series') }}: </span>
          <select v-if="measurementSeries.length > 0" v-model="userChoices.measureUuid" class="select-basic" >
            <option value="" disabled>{{ $t('pages.history_seriesEdit.modals.move.choose') }}</option>
            <option v-for="measurementSerie in measurementSeries" :key="measurementSerie.measureUuid" :value="measurementSerie.measureUuid">
              {{ $tc('pages.history_seriesEdit.modals.move.selection-series', measurementSerie.nbMeasures, {beginDate: displayDateTimeByLocaleFromISOString(measurementSerie.beginDate), endDate: displayDateTimeByLocaleFromISOString(measurementSerie.endDate)}) }}
            </option>
          </select>
          <span v-else class="italic">{{ $t('pages.history_seriesEdit.modals.move.no-data') }}</span>
        </div>
      </div>

      <!-- Validation -->
      <div class="text-center">
        <button v-if="userChoices.measureUuid !== ''" @click="onClickValidate" class="mt-6 btn btn--success">
          <i class="fas fa-check mr-2"></i>{{ $t('pages.history_seriesEdit.modals.move.validate') }}
        </button>
      </div>

    </div>

  </modal>
</template>

<script>
import modal from "./modal";
import {apiGetRequest, apiPostRequest, displayDateByLocale, displayDateTimeByLocaleFromISOString} from "../../utils";
import {
  API_CULTURES_SHOW,
  API_CULTURES_TRANSMITTERS_SUFFIX,
  API_FARMERS_CULTURES_ARCHIVED_SUFFIX,
  API_FARMERS_CULTURES_SUFFIX,
  API_FARMERS_INDEX,
  API_FARMERS_SHOW, API_HISTORY_CULTURES_TRANSMITTERS, API_HISTORY_CULTURES_TRANSMITTERS_SENSORS,
  API_MEASURES_SERIES,
  API_TRANSMITTERS_SENSORS_SUFFIX,
  API_TRANSMITTERS_SHOW
} from "../../utils/constants";

export default {
  name: "move-measures-modal",
  components: {modal},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    toggle: {
      type: Function,
      required: true
    },
    selectedPoints: {
      type: Array,
      required: true
    },
    currentMeasureUuid: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      setupSelected: false,
      isLoaded: {
        farmers: false,
        cultures: false,
        transmitters: false,
        sensors: false,
        measurementSeries: false
      },
      farmers: null,
      cultures: [],
      transmitters: [],
      sensors: [],
      measurementSeries: [],
      userChoices: {
        isActualSetup: -1,
        farmerId: -1,
        cultureId: -1,
        transmitterId: -1,
        sensorId: -1,
        measureUuid: '',
      }
    }
  },
  mounted() {
  },
  methods: {
    onChangeSetup: function(){
      this.setupSelected = true
      if(this.$store.getters.userIsAdmin){
        this.loadFarmers()
      }else{
        this.userChoices.farmerId = this.$store.state.user.id
        this.loadCultures()
      }
    },
    loadFarmers: function() {

      this.resetFarmerChoice()
      this.resetCultureChoice()
      this.resetTransmitterChoice()
      this.resetSensorChoice()
      this.resetMeasurementSeriesChoice()

      apiGetRequest(API_FARMERS_INDEX)
          .then((res) => {
            this.farmers = res.data
            this.isLoaded.farmers = true
          })
          .catch(() => this.toggle())
    },
    loadCultures: function() {
      const farmerId = this.$store.getters.userIsAdmin ? this.userChoices.farmerId : this.$store.state.user.id
      const url = this.userChoices.isActualSetup ?  API_FARMERS_SHOW + farmerId + API_FARMERS_CULTURES_SUFFIX : API_FARMERS_SHOW + farmerId + API_FARMERS_CULTURES_ARCHIVED_SUFFIX

      this.resetCultureChoice()
      this.resetTransmitterChoice()
      this.resetSensorChoice()
      this.resetMeasurementSeriesChoice()

      apiGetRequest(url)
          .then((res) => {
            this.cultures = res.data
            this.isLoaded.cultures = true
            console.log(this.cultures)
          })
          .catch(() => this.toggle())
    },
    loadTransmitters: function() {

      this.resetTransmitterChoice()
      this.resetSensorChoice()
      this.resetMeasurementSeriesChoice()

      const url = this.userChoices.isActualSetup
          ?  API_CULTURES_SHOW + this.userChoices.cultureId + API_CULTURES_TRANSMITTERS_SUFFIX
          : API_HISTORY_CULTURES_TRANSMITTERS(this.userChoices.cultureId)

      apiGetRequest(url)
          .then((res) => {
            this.transmitters = res.data
            this.isLoaded.transmitters = true
            console.log(this.cultures)
          })
          .catch(() => this.toggle())
    },
    loadSensors: function() {

      this.resetSensorChoice()
      this.resetMeasurementSeriesChoice()

      const url = this.userChoices.isActualSetup
          ?  API_TRANSMITTERS_SHOW + this.userChoices.transmitterId + API_TRANSMITTERS_SENSORS_SUFFIX
          : API_HISTORY_CULTURES_TRANSMITTERS_SENSORS(this.userChoices.cultureId, this.userChoices.transmitterId)

      apiGetRequest(url)
          .then((res) => {
            this.sensors = res.data
            this.isLoaded.sensors = true
          })
          .catch(() => this.toggle())
    },
    loadMeasurementSeries: function() {

      this.resetMeasurementSeriesChoice()

      apiPostRequest(API_MEASURES_SERIES, {
        "cultureId": this.userChoices.cultureId,
        "transmitterId": this.userChoices.transmitterId,
        "sensorId": this.userChoices.sensorId,
        "currentMeasureUuid": this.currentMeasureUuid
      })
          .then((res) => {
            this.measurementSeries = res.data
            this.isLoaded.measurementSeries = true
          })
          .catch(() => this.toggle())
    },
    onClickValidate: function() {
      this.$emit('onValidClicked', this.userChoices)
      this.toggle()
      this.resetSetupChoice()
      this.resetFarmerChoice()
      this.resetCultureChoice()
      this.resetTransmitterChoice()
      this.resetSensorChoice()
      this.resetMeasurementSeriesChoice()
    },
    resetSetupChoice: function(){
      this.setupSelected = false
      this.userChoices.isActualSetup = -1
    },
    resetFarmerChoice: function(){
      this.farmers = null
      this.isLoaded.farmers = false
      this.userChoices.farmerId = -1
    },
    resetCultureChoice: function(){
      this.isLoaded.cultures = false
      this.cultures = []
      this.userChoices.cultureId = -1
    },
    resetTransmitterChoice: function(){
      this.isLoaded.transmitters = false
      this.transmitters = []
      this.userChoices.transmitterId = -1
    },
    resetSensorChoice: function(){
      this.isLoaded.sensors = false
      this.sensors = []
      this.userChoices.sensorId = -1
    },
    resetMeasurementSeriesChoice: function(){
      this.isLoaded.measurementSeries = false
      this.measurementSeries = []
      this.userChoices.measureUuid = ""
    },
    /**
     * Utility function to display correctly the date time according the current locale loaded
     * @param strDate Current date
     * @returns {string} Date well formatted
     */
    displayDateTimeByLocaleFromISOString: function(strDate){
      return displayDateTimeByLocaleFromISOString(strDate)
    },
    displayDateTimeArrayByLocale: function(d){
      return displayDateByLocale(d)
    }
  }
}
</script>

<style scoped>

</style>
