import Login from "../views/Login";

const publicRoutes = [
  {
    name: 'landing',
    path: '/',
    component: Login,
    meta: {
      auth: false,
    }
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    props: true, // To be able to pass props as route params
    meta: {
      auth: false
    }
  },
]

export default publicRoutes
