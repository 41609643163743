<template>
  <div class="text-gray-400">
    <span @click="previousPage" class="mr-3 roundShape" :class="{ 'text-gray-200': activePage === 1, 'roundShape-hover': activePage > 1 }">
          <i class="fas fa-chevron-left"></i>
    </span>
    <span @click="nextPage" class="roundShape" :class="{ 'text-gray-200': activePage === pagination.totalPages, 'roundShape-hover': activePage < pagination.totalPages }">
      <i class="fas fa-chevron-right"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    totalRows: {
      type: Number,
      required: true
    },
    limitPerPage: {
      type: Number,
      required: true
    },
    activePage: {
      type: Number,
      required: true
    }
  },
  computed: {
    pagination: function() {
      let val = Math.ceil(this.totalRows / this.limitPerPage)
      return {
        totalPages: val === 0 ? 1 : val // To have at least one page
      }
    }
  },
  methods: {
    /**
     * Next page clicked
     */
    nextPage: function() {
      const newActivePage = this.activePage+1
      if(newActivePage <= this.pagination.totalPages){
        this.notifyPaginationChanged(newActivePage)
      }
    },
    /**
     * Previous page clicked
     */
    previousPage: function() {
      const newActivePage = this.activePage-1
      if(newActivePage >= 1){
        this.notifyPaginationChanged(newActivePage)
      }
    },
    /**
     * Notify the parent that the pagination has changed.
     * We send as argument an object containing the new ActivePage and from / to indexes
     * to slice the data to match the current pagination
     * @param newActivePage The new ActivePage number
     */
    notifyPaginationChanged: function(newActivePage) {
      this.$emit('paginationChanged', {
        activePage: newActivePage,
        dataFrom: (newActivePage-1) * this.limitPerPage,
        dataTo: newActivePage * this.limitPerPage
      })
    }
  }
}
</script>

<style scoped>
  .roundShape {
    @apply rounded-full px-3.5 py-2;
  }
  .roundShape-hover{
    @apply cursor-pointer hover:bg-gray-100;
  }
</style>
