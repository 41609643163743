<template>
  <div>

    <!-- Confirm modal to delete measures -->
    <confirm-modal @cancel="toggleModalDelete" @yes="deleteSelectedMeasures" :visible="modals.delete.isVisible" :toggle="toggleModalDelete">
      {{ $tc('pages.history_seriesEdit.modals.delete.message', this.selectedPoints.length, {count: this.selectedPoints.length}) }}
    </confirm-modal>

    <!-- Move measures modal -->
    <move-measures-modal v-if="measures.isLoaded" @onValidClicked="moveSelectedMeasures" :visible="modals.move.isVisible" :toggle="toggleModalMove" :selected-points="selectedPoints" :currentMeasureUuid="measureUuid" />

    <!-- Main data -->
    <div v-if="measures.isLoaded">
      <select-time-period :default-value="selectedTimePeriod" :default-show-data-picker="showDatePicker" :default-start-date="startDate" :default-end-date="endDate" @valueChanged="timePeriodChanged" @submitCustomDate="submitCustomPeriod" class="mt-3"/>

      <!-- Card: edit menu-->
      <div v-if="selectedPoints.length > 0">
        <div class="card mt-4">
          <h2 class="card__title">{{ $t('pages.history_seriesEdit.cards.edit-menu.title') }}</h2>
          <div><b class="text-xl">{{ selectedPoints.length }}</b> {{ $tc('pages.history_seriesEdit.cards.edit-menu.points-selected', selectedPoints.length) }}</div>
          <button @click="onDeleteClicked" class="mt-2 btn btn--danger"><i class="mr-2 fa fa-trash-alt"></i>{{ $t('pages.history_seriesEdit.cards.edit-menu.delete') }}</button>
          <button @click="onMoveClicked" class="mt-2 ml-4 btn btn--primary"><i class="mr-2 fas fa-arrows-alt"></i>{{ $t('pages.history_seriesEdit.cards.edit-menu.move') }}</button>
          <button @click="onCancelClicked" class="ml-4 btn btn--gray"><i class="mr-2 fas fa-ban"></i>{{ $t('pages.history_seriesEdit.cards.edit-menu.cancel') }}</button>
        </div>
      </div>

      <!-- Card: chart -->
      <div class="card mt-4">
        <h2 class="card__title">{{ $t('pages.history_seriesEdit.cards.measures.title', {count: this.measures.data.sensors[0].values.length}) }}</h2> <!-- In this edit view, we always have only one sensor -->
        <highcharts :options="chartOptions" ref="lineCharts" constructor-type="chart"/>
      </div>
    </div>
  </div>

</template>

<script>

import {getFromAndToQueryParam, getFromQueryParam} from "../../../../utils/chartUtils";
import {apiGetRequest, apiPostRequest, displayAlertSuccess} from "../../../../utils";
import {API_HISTORY_SERIES_SHOW, API_MEASURES_DELETE, API_MEASURES_MOVE} from "../../../../utils/constants";
import {getLineChartOptionsByMeasureType} from "../../../../utils/chartUtils";
import {Chart} from "highcharts-vue";
import SelectTimePeriod from "../../../../components/charts/SelectTimePeriod";
import ConfirmModal from "../../../../components/modals/confirm-modal";
import MoveMeasuresModal from "../../../../components/modals/move-measures-modal";

export default {
  name: "SeriesHistoryEdit",
  components: {SelectTimePeriod, highcharts: Chart, ConfirmModal, MoveMeasuresModal},
  props: {
    measureUuid: {
      type: [String],
      required: true
    },
    measureTypeId: {
      type: [String, Number], // Props from URL can be a String
      required: true
    }
  },
  data: function(){
    return {
      selectedTimePeriod: 4,
      showDatePicker: false,
      startDate:"",
      endDate:"",
      measures: {
        isLoaded: false,
        data: null
      },
      chartOptions: null,
      selectedPoints: [],
      modals:{
        delete: {
          isVisible: false
        },
        move: {
          isVisible: false
        }
      }
    }
  },
  mounted() {
    this.loadMeasures()
  },
  methods: {
    /**
     * Load all measures from the API
     * It will fetch all measures and keep only those related to the wanted MeasureType.
     * Finally, generate an editable chart
     */
    loadMeasures: function() {
      const fromQueryParam = getFromQueryParam(this.selectedTimePeriod)
      this.refreshCharts(fromQueryParam)
    },
    submitCustomPeriod: function(rangeDates){
      this.startDate = rangeDates.start
      this.endDate = rangeDates.end
      const fromAndToQueryParams = getFromAndToQueryParam(rangeDates)
      this.refreshCharts(fromAndToQueryParams)
    },

    refreshCharts : function (queryParams){
      this.measures.isLoaded = false
      apiGetRequest(API_HISTORY_SERIES_SHOW(this.measureUuid, queryParams))
          .then((res) => {
            this.measures.data = res.data.measures.filter(measureType => measureType.id === parseInt(this.measureTypeId))[0]
            this.chartOptions = getLineChartOptionsByMeasureType(this.measures.data, () => {}, true)
            this.makeTheChartEditable()
            this.measures.isLoaded = true
          })
    },
    timePeriodChanged: function(selectedTimePeriod){
      this.selectedTimePeriod = selectedTimePeriod
      if(selectedTimePeriod !== 5){
        this.loadMeasures()
        this.showDatePicker = false
      }else{
        this.showDatePicker = true
      }
    },
    /**
     * Define all others chartOptions to have a full editable chart as we want
     */
    makeTheChartEditable: function(){
      this.chartOptions.exporting.enabled = false
      this.chartOptions.chart.zoomType = 'x' // To be able to select a zone (X axis)
      this.chartOptions.series[0].allowPointSelect = true
      this.chartOptions.chart.events = {
        // Selection is triggered when we select some part of the chart with mouse drag
        selection: this.selectPointsByDrag,
        // Click is triggered when we click on the chart but outside a line / point
        click: this.unselectAllPoints
      }
      this.chartOptions.plotOptions = {
        series: {
          turboThreshold: 0, // To display all values without limit of numbers
          point:{
            // Events when we select or unselect one point individually
            events: {
              select: this.updatePointsSelection,
              unselect: this.updatePointsSelection
            }
          }
        }
      }
    },
    /**
     * Select some points by click and drag the mouse
     * @param e The event
     */
    selectPointsByDrag: function(e) {
      e.preventDefault() // To deactivate the zoom event
      let chart = this.$refs.lineCharts.chart
      // Travel each points of the chart and select all that are contains in the selection [min, max selected]
      chart.series[0].points.forEach(point => {
        if(point.x >= e.xAxis[0].min && point.x <= e.xAxis[0].max) {
          if(!point.selected){
            point.select(true, true) // Will triggered the select event
          }
        }
      })
    },
    /**
     * Update intern variable that references all current selected points
     */
    updatePointsSelection: function() {
      let chart = this.$refs.lineCharts.chart
      this.selectedPoints = chart.getSelectedPoints()
    },
    /**
     * unselect all points
     */
    unselectAllPoints: function () {
      let chart = this.$refs.lineCharts.chart
      chart.getSelectedPoints().forEach(p => p.select(false))
      this.updatePointsSelection()
    },
    /**
     * On cancel button clicked (edition menu)
     */
    onCancelClicked: function() {
      this.unselectAllPoints()
    },
    /**
     * On delete button clicked (edition menu)
     */
    onDeleteClicked: function(){
      this.toggleModalDelete()
    },
    /**
     * On move button clicked (edition menu)
     */
    onMoveClicked: function(){
      this.toggleModalMove()
    },
    /**
     * Function called to toggle the confirm delete modal
     */
    toggleModalDelete: function(){
      this.modals.delete.isVisible = !this.modals.delete.isVisible
    },
    /**
     * Function called to toggle the move modal
     */
    toggleModalMove: function(){
      this.modals.move.isVisible = !this.modals.move.isVisible
    },
    /**
     * Delete all selected measures
     */
    deleteSelectedMeasures: function() {
      // Send the request with all events in json data
      apiPostRequest(API_MEASURES_DELETE, this.selectedPoints.map(p => p.docId))
          .then(() => {
            this.loadMeasures() // Reload the new chart
            let successMsg = this.$tc('pages.history_seriesEdit.success.delete', this.selectedPoints.length, {count: this.selectedPoints.length})
            this.displaySuccessAlert(successMsg)
            this.selectedPoints = [] // Reset the selected points
          })
          .finally(() => {
            this.toggleModalDelete()
          })
    },
    /**
     * Move all selected measures
     */
    moveSelectedMeasures: function(userChoices){
      // Send the request with all events in json data
      apiPostRequest(API_MEASURES_MOVE, {
        "docIds": this.selectedPoints.map(p => p.docId),
        "measureUUIDtarget": userChoices.measureUuid,
      })
          .then(() => {
            this.loadMeasures() // Reload the new chart
            let successMsg = this.$tc('pages.history_seriesEdit.success.move', this.selectedPoints.length, {count: this.selectedPoints.length})
            this.displaySuccessAlert(successMsg)
            this.selectedPoints = [] // Reset the selected points
          })
          .catch(() => {
            this.toggleModalMove()
          })
    },
    /**
     * Display the success alert
     */
    displaySuccessAlert: function(msg){
      displayAlertSuccess(msg)
    },
  }
}
</script>

<style scoped>

</style>
