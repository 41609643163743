<template>

    <!-- Alert notifications container -->
    <div class="fixed flex flex-col center-horizontal z-10">

      <!-- Each alert notification -->
      <div v-for="alertNotification in $store.state.alertNotifications" :key="alertNotification.id" class="alert-wrapper">
        <!-- Colored left border -->
        <div class="border-l-4 rounded-tl-sm rounded-bl-sm" :class="alertNotification.type === AlertTypes.SUCCESS
                    ? 'border-success'
                    : alertNotification.type === AlertTypes.WARNING
                        ? 'border-warning'
                        : 'border-danger'">

          <div class="alert-content">
            <!-- Left part : Icon -->
            <div class="flex flex-col justify-center mr-4 text-2xl">
              <i v-if="alertNotification.type === AlertTypes.SUCCESS" class="text-green-400 fas fa-check-circle"></i>
              <i v-if="alertNotification.type === AlertTypes.WARNING" class="text-yellow-400 fas fa-exclamation-circle"></i>
              <i v-if="alertNotification.type === AlertTypes.DANGER" class="text-red-400 fas fa-times-circle"></i>
            </div>

            <!-- Middle part : Text -->
            <div>
              <h1 class="font-semibold font-medium">{{ displayTitle(alertNotification.type) }}</h1>
              <p class="text-gray-500 font-light">{{ alertNotification.msg }}</p>
            </div>

            <!-- Right part : Close -->
            <div class="ml-10 -mr-1">
              <i @click="closeAlert(alertNotification.id)" class="text-gray-300 text-xl fas fa-times cursor-pointer"></i>
            </div>
          </div>

        </div>
      </div>

    </div>

</template>

<script>

import {AlertTypes} from "../../utils/constants";

export default {
  name: "alert-notifications",
  data() {
    return {}
  },
  computed: {
    AlertTypes: function() {
      return AlertTypes
    }
  },
  methods: {
    /**
     * Display the right alert title by the type asked
     * @param type The alert type
     * @returns The right alert according the alert type in parameter
     */
    displayTitle: function(type) {
      switch (type) {
        case AlertTypes.SUCCESS :
          return this.$t('words.alertSuccess')
        case AlertTypes.WARNING :
          return this.$t('words.alertWarning')
        case AlertTypes.DANGER :
          return this.$t('words.alertDanger')
        default:
          return this.$t('words.alertDanger')
      }
    },
    /**
     * Close the clicked alert
     * @param id The clicked alert id
     */
    closeAlert: function(id) {
      this.$store.commit('removeAlertNotification', id)
    }
  }
}
</script>

<style scoped>
  .center-horizontal {
    /* To center horizontaly */
    left: 50%;
    transform: translateX(-50%);
  }
  .alert-wrapper {
    @apply mt-4 max-w-3xl border-t border-r border-b border-gray-300 rounded-sm shadow-md;
    transition: margin 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .border-success {
    @apply border-green-400;
  }
  .border-warning {
    @apply border-yellow-400;
  }
  .border-danger{
    @apply border-red-400;
  }

  .alert-content {
    @apply py-3 px-5 bg-white text-left flex
  }
</style>
