<template>
  <button @click="$emit('clicked')" class="btn" :class="classBtnType">
    <span v-if="loading"><i class="fas fa-spinner fa-spin"></i></span>
    <span v-else><slot/></span>
  </button>
</template>

<script>
export default {
  name: "btn-with-loader",
  props: {
    classBtnType: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
