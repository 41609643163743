import Vue from 'vue'
import App from "./App"
import HighchartsVue from 'highcharts-vue'
import {extend, ValidationObserver, ValidationProvider} from "vee-validate"
import * as rules from "vee-validate/dist/rules";
import Multiselect from "vue-multiselect";
import PublicLayout from "./layouts/PublicLayout"
import AppLayout from "./layouts/AppLayout"
import router from "./router"
import store from "./store"
import {i18n} from "./locales"
import './assets/app.css'
import './assets/css/all-font-awesome.min.css'
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
import highchartsNoData from "highcharts/modules/no-data-to-display";
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet';

// Import globally only layouts that we'll use
Vue.component('PublicLayout', PublicLayout)
Vue.component('AppLayout', AppLayout)

// For Vue multiselect lib
Vue.component('multiselect', Multiselect)

// For form validation (VeeValidate lib) + register all basic rules present in the lib
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


// HighChart initialisation and options
Vue.use(HighchartsVue)
exportingInit(Highcharts);
offlineExporting(Highcharts) // Needed to do image export locally in place of highchart servers
highchartsNoData(Highcharts);
Highcharts.setOptions({
  lang: {
    noData: i18n.t('words.no-data-found')
  }
});

Vue.config.productionTip = false
//Vue.config.devtools = false

new Vue({
  render: h => h(App),
  router: router,
  store: store,
  i18n: i18n
}).$mount('#app')

