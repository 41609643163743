<template>
  <div>

    <!-- Rows per page chooser -->
    <div class="text-sm mb-2">
      <per-page-chooser :initialValue="limitPerPage" @nbRowsChanged="updatedNbRowsDisplayed"/>
    </div>

    <!-- Data table -->
    <table>
      <!-- Table head-->
      <tr>
        <th v-for="(val, key, i) in columns" :key="i">
          {{ val }}
        </th>
      </tr>

      <!-- No data found if the case-->
      <tr v-if="filteredData.length === 0">
        <td :colspan="Object.keys(columns).length" class="hover:bg-gray-50">{{ $t('words.no-data-found') }}</td>
      </tr>

      <!-- Table body-->
      <tr v-else v-for="(dataItem, i) in filteredData" :key="i" class="hover:bg-gray-50">
        <td v-for="(colVal, colKey, i) in columns" :key="i">

          <!-- Create buttons if this is the actions column -->
          <div v-if="colKey === 'actions'">
            <router-link v-for="(action, actionKey, i) in dataItem[colKey]" :key="i" :to="action.to">
              <button class="btn" :class="{'ml-3': i > 0, 'btn--primary': actionKey === 'see', 'btn--warning': actionKey === 'edit'}">
                {{ action.text }}
              </button>
            </router-link>
          </div>

          <!-- Otherwise display the data normally. Automatically bind data that has a key corresponding the columns key-->
          <span v-else>
            {{ dataItem[colKey] }}
          </span>

        </td>
      </tr>
    </table>

    <!-- Table info -->

    <div class="flex flex-row-reverse">
      <div class="flex">

        <div class="text-sm mr-10">
          <table-info :totalRows="data.length" :activePage="activePage" :limitPerPage="limitPerPage"/>
        </div>

        <div>
          <pagination @paginationChanged="paginationChanged" :total-rows="data.length" :active-page="activePage" :limit-per-page="limitPerPage"/>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import PerPageChooser from "./per-page-chooser";
import TableInfo from "./table-info";
import Pagination from "./pagination";

export default {
  name: "DataTable",
  components: {Pagination, PerPageChooser, TableInfo},
  props: {
    columns: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      filteredData: [],
      limitPerPage: 10,
      activePage: 1
    }
  },
  methods: {
    /**
     * This method is triggered when the rows-chooser emit event for rows selection changed
     * @param nbRows The current number of rows selected
     */
    updatedNbRowsDisplayed: function(nbRows) {
      this.limitPerPage = nbRows
      this.filteredData = this.data.slice(0, nbRows)
      this.activePage = 1
    },
    paginationChanged: function(pagination) {
      this.activePage = pagination.activePage
      this.filteredData = this.data.slice(pagination.dataFrom, pagination.dataTo)
    },
  }
}
</script>

<style scoped>

</style>
