import Vue from "vue";
import Vuex from "vuex";
import jwtDecode from "jwt-decode";
import {i18n} from "@/locales";
import moment from "moment";

Vue.use(Vuex)

const defaultHuntCurve = {id:null,createdAt:i18n.t("pages.culture_show.hunt.hunt-curve-default")}
const defaultPredictionModelLight = {value:'LIGHT',name:i18n.t('pages.culture_show.prediction.model.light')}

const store = new Vuex.Store({
  state: {
    layout: 'PublicLayout',
    accessToken: window.localStorage.getItem("access-token"),
    refreshToken: window.localStorage.getItem("refresh-token"),
    user: fetchUserInfo(),
    requestError: false,
    pendingEventsCount: 0,
    // All alert notification that is currently displayed
    alertNotifications: [],
    combined:{
      loadingMeasures:false,
      isMeasuresLoaded:false,
      cultureChartMeasureTypes:{},
    },
    hunt:{
      selectedHuntCurveParameters:defaultHuntCurve,
      loadingMeasuresHunt:false,
      isMeasuresLoadedHunt:false,
      selectedCombineMode:'MEAN',
      selectedCombineFreq:"BI_HOURLY",
      allCombineFreq:[
        {value:"BI_HOURLY",hours:2, translateKey:'pages.culture_show.hunt.freq-bi_hourly'},
        {value:"HOURLY",hours:1,translateKey:'pages.culture_show.hunt.freq-hourly'},
        {value:"DAILY",hours:24, translateKey:'pages.culture_show.hunt.freq-daily'}],
      huntParameters:{},
      huntCurveParameters:[defaultHuntCurve],
      lastHuntX:-1,
      huntValues:[],
    },
    prediction:{
      predictionValues:[],
      lastPredictSize:0,
      selectedPredictionDateUntil:'25-05-2025T00:00',
      isLoading:false,
      defaultModels:[defaultPredictionModelLight],
      selectedModel:defaultPredictionModelLight.value,
      models:[defaultPredictionModelLight],
      isMeasuresLoaded:false,
      percentageCompleted:0.0,
    }
  },
  mutations: {
    resetAllState(state){
      console.log('Resetting all state for prediction..')
      state.combined={
        loadingMeasures:false,
            isMeasuresLoaded:false,
            cultureChartMeasureTypes:{},
      }
      state.hunt={
        selectedHuntCurveParameters:defaultHuntCurve,
            loadingMeasuresHunt:false,
            isMeasuresLoadedHunt:false,
            selectedCombineMode:'MEAN',
            selectedCombineFreq:"BI_HOURLY",
            allCombineFreq:[
          {value:"BI_HOURLY",hours:2, translateKey:'pages.culture_show.hunt.freq-bi_hourly'},
          {value:"HOURLY",hours:1,translateKey:'pages.culture_show.hunt.freq-hourly'},
          {value:"DAILY",hours:24, translateKey:'pages.culture_show.hunt.freq-daily'}],
            huntParameters:{},
        huntCurveParameters:[defaultHuntCurve],
            lastHuntX:-1,
            huntValues:[]
      }
      state.prediction = {
        predictionValues:[],
            lastPredictSize:0,
            selectedPredictionDateUntil:'25-05-2025T00:00',
            isLoading:false,
            defaultModels:[defaultPredictionModelLight],
            selectedModel:defaultPredictionModelLight.value,
            models:[defaultPredictionModelLight],
            isMeasuresLoaded:false,
            percentageCompleted:0.0,
      }
    },
    selectHuntParameters(state, {val}){
      if(val!==undefined&&val!==null){
        state.hunt.selectedHuntCurveParameters = val
        state.hunt.isMeasuresLoadedHunt=false
        state.hunt.loadingMeasuresHunt=false
      }
    },
    setLayout(state, newLayout){
      state.layout = newLayout
    },
    setTokens(state, data){
      state.accessToken = data.access_token
      state.refreshToken = data.refresh_token
      state.user = jwtDecode(data.access_token)
      window.localStorage.setItem("access-token", data.access_token)
      window.localStorage.setItem("refresh-token", data.refresh_token)
    },
    logout(state) {
      state.user = null
      state.accessToken = null
      state.refreshToken = null
      window.localStorage.removeItem("access-token")
      window.localStorage.removeItem("refresh-token")
    },
    setRequestError(state){
      state.requestError = true
    },
    resetRequestError(state){
      state.requestError = false
    },
    setPendingEventsCount(state, count) {
      state.pendingEventsCount = count
    },
    addAlertNotification(state, {type, msg}) {
      const id = Date.now()
      state.alertNotifications.unshift({
        id: id,
        type: type,
        msg: msg
      })
      // Automatically remove the notification after 5sec
      setTimeout(() => {
        removeAlertNotification(state, id)
      }, 5000);
    },
    removeAlertNotification(state, id) {
      removeAlertNotification(state, id)
    },
  },
  getters: {
    userEmail(state) {
      return state.user === null ? null : state.user.sub
    },
    userRoles(state) {
      return state.user === null ? null : state.user.roles
    },
    userIsAdmin(state){
      return doesUserHasRole(state, 'admin')
    },
    userIsFarmer(state) {
      return doesUserHasRole(state, 'farmer')
    },
    isModelDefault(state){
      return model=>state.prediction.defaultModels.map(m=>m.value).includes(model)
    },
    lastGraphDate(state){
      if(state.combined.cultureChartMeasureTypes.data!==undefined && state.combined.cultureChartMeasureTypes.data!==null){
          let valuesLength = state.combined.cultureChartMeasureTypes.data.sensors[0].values.length
          return new Date(state.combined.cultureChartMeasureTypes.data.sensors[0].values[valuesLength-1].timestamp)
      } else if(state.combined.cultureChartMeasureTypes.sensors !==undefined && state.combined.cultureChartMeasureTypes.sensors !==null){
          let valuesLength = state.combined.cultureChartMeasureTypes.sensors[0].values.length
          return  new Date(state.combined.cultureChartMeasureTypes.sensors[0].values[valuesLength-1].timestamp)
      }
      return null
    },
    minValuePredictionDate(state){
      return (date)=> date!==null ? moment(date).add(1 + state.hunt.allCombineFreq.find(freq=>freq.value===state.hunt.selectedCombineFreq).hours,'hour').format("YYYY-MM-DDTHH:mm") : ""
    },
  }
})

/**
 * Decode the access-token jwt in LocalStorage and return a user object contains payload info.
 * @returns {object|null} user object if the jwt is well formatted, null otherwise
 */
function fetchUserInfo() {
  let decodedJwt = null
  try {
    decodedJwt = jwtDecode(window.localStorage.getItem("access-token"))
    // eslint-disable-next-line no-empty
  }catch(e){}
  return decodedJwt
}

function doesUserHasRole(state, role) {
  return state.user === null ? false : state.user.roles.includes(role)
}

/**
 * Remove a specific alert notification
 * @param state The global state
 * @param id The notification ID we want to remove
 */
function removeAlertNotification(state, id) {
  const index = state.alertNotifications.findIndex(notif => notif.id === id)
  if(index !== -1){
    state.alertNotifications.splice(index, 1)
  }
}

export default store
