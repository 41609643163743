<template>
  <div v-if="isLoaded">

    <!-- new_transmitter events -->
    <div class="card">
      <h2 class="card__title">{{ $t('pages.pendingEvent_index.new_transmitter-title', {count: this.events.newTransmitter.length}) }}</h2>

      <p class="mb-4">{{ $t('pages.pendingEvent_index.new_transmitter-description') }}</p>
      <!-- No events-->
      <div v-if="this.events.newTransmitter.length === 0" class="italic">
        {{ $t('pages.culture_events.no-event-now') }}
      </div>
      <!-- Display events-->
      <div v-else class="flex flex-wrap">
        <div v-for="(event, i) in events.newTransmitter" :key="event.id">
          <new-transmitter-event :event="event" :cultures="cultures" @acquitted="oneEventAcquitted(event, i)" />
        </div>
      </div>
    </div>

    <!-- All events by culture -->
    <div v-if="culturesTable.data" class="card mt-4">
      <h2 class="card__title">{{ $t('pages.pendingEvent_index.eventsByCulture-title', {count: culturesTable.data.length}) }}</h2>
      <data-table :columns="culturesTable.columns" :data="culturesTable.data" />
    </div>

  </div>
</template>

<script>
import {apiGetRequest, displayAlertSuccess} from "../../../utils";
import {
  API_CULTURES_INDEX,
  API_EVENTS_PENDING, API_FARMERS_CULTURES_SUFFIX, API_FARMERS_SHOW, API_USERS_INDEX
} from "../../../utils/constants";
import NewTransmitterEvent from "../../../components/events/NewTransmitterEvent";
import DataTable from "../../../components/general/datatable/data-table";

export default {
  name: "PendingEventIndex",
  components: {NewTransmitterEvent, DataTable},
  data: function() {
    return {
      userWeWantId: this.$route.query.userId,
      isLoaded: true,
      cultures: [],
      events: {
        newTransmitter: []
      },
      culturesTable : {
        columns: {
          name: this.$t('words.name'),
          type: this.$t('words.type'),
          nbPendingEvents: this.$t('words.nbPendingEvents'),
          actions: this.$t('words.actions'),
        },
        data: null
      }
    }
  },
  mounted() {
    this.isLoaded = false

    let allCulturesRoute = API_CULTURES_INDEX
    let allPendingEventsRoute = API_EVENTS_PENDING

    // Only Admin can specify which user he want to see
    if(this.$store.getters.userIsAdmin){
      allCulturesRoute = API_FARMERS_SHOW + "/" + this.userWeWantId + API_FARMERS_CULTURES_SUFFIX
      allPendingEventsRoute = API_USERS_INDEX + "/" + this.userWeWantId + "/events/pending"
    }

    // Fetch all cultures for selection
    apiGetRequest(allCulturesRoute)
        .then((res) => {
          this.cultures = res.data

          this.culturesTable.data = res.data.filter((c => c.nbPendingEvents > 0)).map((c) => {
            c.type = `${this.$t('backend_trans_keys.'+c.typeTransKey)} (${this.$t('backend_trans_keys.'+c.varietyTransKey)})`
            c.actions = {
              see: {
                to: {name: 'culture_events', params:{id: c.id}},
                text: this.$t('words.seeEvents')
              }
            }
            return c
          })

          // Fetch all pending events that are not related to a culture
          apiGetRequest(allPendingEventsRoute)
              .then((res) => {
                this.events.newTransmitter = res.data.newTransmitterEvents
                this.isLoaded = true
              })
        })
  },
  methods: {
    /**
     * One event has been acquitted
     * @param event The acquitted event
     * @param arrayIndex The position in its respective array
     */
    oneEventAcquitted: function(event, arrayIndex) {
      this.events.newTransmitter.splice(arrayIndex, 1)
      // Don't forget to update the global stored pendingEventsCount and display success alert
      this.$store.commit('setPendingEventsCount', this.$store.state.pendingEventsCount - 1)
      this.displaySuccessAlert(1)
    },
    /**
     * Display the success alert
     * @param count The number of events that has been acquitted
     */
    displaySuccessAlert: function(count){
      const successMsg = this.$tc('pages.culture_events.success-message', count)
      displayAlertSuccess(successMsg)
    },
  }
}
</script>

<style scoped>

</style>
