<template>
  <ul>
    <router-link :to="{name: 'inventory'}">
      <li @click="fetchAllPendingEventsCount" class="menu-item"><i class="fas fa-list icon"></i> <span class="opacity-100">{{ $t('pages.inventory.title') }}</span></li>
    </router-link>
    <router-link :to="{name: 'culture_index'}">
      <li @click="fetchAllPendingEventsCount" class="menu-item"><i class="fas fa-seedling icon"></i> <span class="opacity-100">{{ $t('pages.culture_index.title') }}</span></li>
    </router-link>
    <router-link :to="{name: 'pendingEvent_index'}">
      <li @click="fetchAllPendingEventsCount" class="menu-item relative">
        <i class="far fa-bell icon"></i> <span class="opacity-100">{{ $t('pages.pendingEvent_index.title') }}</span>
        <span v-if="$store.state.pendingEventsCount > 0" class="badge right-2 top-2.5">{{ $store.state.pendingEventsCount }}</span>
      </li>
    </router-link>
    <router-link :to="{name: 'history_cultureIndex'}">
      <li @click="fetchAllPendingEventsCount" class="menu-item"><i class="fas fa-history icon"></i> <span class="opacity-100">{{ $t('pages.history_cultureIndex.title') }}</span></li>
    </router-link>
  </ul>
</template>

<script>
import {apiGetRequest} from "../../utils";
import {
  API_EVENTS_ALL_PENDING_COUNT
} from "../../utils/constants";

export default {
  name: "farmer-menu",
  mounted() {
    this.fetchAllPendingEventsCount()
  },
  methods: {
    /**
     * API call to count all pending events for the connected user
     * /!\ Each time we click on a menu item, we fetch the total count to see if we have to update it or not
     */
    fetchAllPendingEventsCount: function() {
      apiGetRequest(API_EVENTS_ALL_PENDING_COUNT)
          .then((res) => {
            const count = res.data.count
            // Update the count in vueX storage only if it is different
            if(count !== this.$store.state.pendingEventsCount){
              this.$store.commit('setPendingEventsCount', res.data.count)
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
