<template>
  <div>
    <div class="text-right mb-4"><button class="btn btn--primary" @click="fitHuntCurve">{{$t('pages.culture_show.hunt.hunt-curve-fit')}}</button></div>
    <div class="grid grid-cols-8 align-middle items-center justify-center gap-4">
      <button :disabled="isDeleteHuntDisabled" :class="classDeleteHunt" @click="onDeleteHuntCurve"><i class="fas fa-trash icon"></i></button>
      <label class="col-span-4">{{$t('pages.culture_show.hunt.select-hunt-curve')}}</label>
      <multiselect :value="hunt.selectedHuntCurveParameters"
                   :ref="hunt.selectedHuntCurveParameters"
                   track-by="id" label="createdAt"
                   :show-labels="false"
                   :searchable="false"
                   :options="hunt.huntCurveParameters"
                   @input="selectHuntParameters"
                   class="inline-block col-span-3"
      >
      </multiselect>
    </div>
  </div>
</template>

<script>
import {apiGetRequest, apiPostRequest} from "@/utils";
import {
  API_CULTURES_GLOBAL_HUNT,
  API_CULTURES_GLOBAL_HUNT_DELETE,
  API_CULTURES_GLOBAL_HUNT_FIT
} from "@/utils/constants";
import {mapState} from "vuex";
import store from "@/store";
import {formatLocalDate, huntCurve} from "@/utils/chartUtils";

export default {
  name: "FitHuntModel",
  props:{
    // selectedCombineMode:{
    //   type:String,
    //   required:true
    // },
    // selectedCombineFreq:{
    //   type:String,
    //   required:true
    // },
    // updateHuntCurve:{
    //   type:Function,
    //   required:true
    // },
    // loadingMeasuresHunt:{
    //   type:Boolean,
    //   required:true,
    //   default:false,
    // },
    // isMeasuresLoadedHunt:{
    //   type:Boolean,
    //   required:true,
    //   default:false,
    // },
    // huntCurveParameters:{
    //   type:Array,
    //   required:true,
    // },
    // selectedHuntCurveParameters:{
    //   type:Object,
    //   required:true,
    // },
    cultureId:{
      type:[String,Number],
      required:true
    },
    updateHuntCallback:{
      type:Function,
      required:false,
      default:null
    },
  },
  data:function(){
    return {
      limitMultiplier:0.035,
      huntColor:'black',
      limitDashStyle:'Dash',
      limitLineWidth:1,
    }
  },
  computed:{
    isDeleteHuntDisabled: function(){
      return this.hunt.selectedHuntCurveParameters.id===null
    },
    classDeleteHunt: function(){
      return `btn ${this.isDeleteHuntDisabled ? 'btn--disabled' : 'btn--danger'} w-10`
    },
    ...mapState(['hunt','prediction','combined'])
  },
  watch:{
    'combined.isMeasuresLoaded':{
      handler(isMeasuresLoaded){
        if(isMeasuresLoaded){
          this.fetchHuntParameters()
        }
      }
    },
    'prediction.isMeasuresLoaded':{
      handler(isMeasuresLoaded){
        if(isMeasuresLoaded){
          this.updateHuntCurve(this.hunt.selectedHuntCurveParameters)
        }
      }
    }
  },
  methods:{
    fitHuntCurve: function(){
      if(!this.hunt.loadingMeasuresHunt) {
        this.hunt.loadingMeasuresHunt = true;
        this.hunt.isMeasuresLoadedHunt = false;
        apiPostRequest(API_CULTURES_GLOBAL_HUNT_FIT(this.cultureId) + `?mode=${this.hunt.selectedCombineMode}&freq=${this.hunt.selectedCombineFreq}`, undefined)
            .then((res)=>{
              if(res.data!==undefined){
                let huntToAdd = {...res.data, createdAt: formatLocalDate(res.data.createdAt)}
                this.hunt.huntCurveParameters = [
                  ...this.hunt.huntCurveParameters.slice(0,1),
                  huntToAdd,
                  ...this.hunt.huntCurveParameters.slice(1)
                ]
                store.commit({type:'selectHuntParameters',val:this.hunt.huntCurveParameters[1]})
                this.updateHuntCurve(this.hunt.huntCurveParameters[1])
              }
            })
            .finally(() => {this.hunt.loadingMeasuresHunt = false;this.hunt.isMeasuresLoadedHunt=true;})
      }
    },
    onDeleteHuntCurve: function(){
      if(this.hunt.selectedHuntCurveParameters.id!==null&&this.hunt.selectedHuntCurveParameters.id!==undefined){
        apiPostRequest(API_CULTURES_GLOBAL_HUNT_DELETE(this.hunt.selectedHuntCurveParameters.id),undefined)
            .then(()=>{
              this.hunt.huntCurveParameters = this.hunt.huntCurveParameters.filter(hcp=>hcp.id!==this.hunt.selectedHuntCurveParameters.id||hcp.id===null)
              store.commit({type:'selectHuntParameters',val:this.hunt.huntCurveParameters[0]})
              this.updateHuntCurve(this.hunt.selectedHuntCurveParameters)
            })
      }
    },
    selectHuntParameters:function(val){
      store.commit({type:'selectHuntParameters',val:val})
      this.updateHuntCurve(this.hunt.selectedHuntCurveParameters)
    },
    fetchHuntParameters: function(){
      if(!this.hunt.loadingMeasuresHunt){
        this.hunt.loadingMeasuresHunt = true;
        this.hunt.isMeasuresLoadedHunt = false;
        apiGetRequest(API_CULTURES_GLOBAL_HUNT(this.cultureId))
            .then((res)=>{
              res.data[0].createdAt = this.$t('pages.culture_show.hunt.hunt-curve-default')
              this.hunt.huntCurveParameters = res.data.map(h=>{return {...h, createdAt: formatLocalDate(h.createdAt)}})
              this.hunt.selectedHuntCurveParameters = this.hunt.huntCurveParameters[0]
              this.updateHuntCurve(this.hunt.selectedHuntCurveParameters.id!==null ? this.hunt.selectedHuntCurveParameters:this.hunt.huntCurveParameters[0])
            })
            .finally(() => this.hunt.loadingMeasuresHunt = false)
      }
    },
    createLimitValues:function(id,values, color=this.huntColor,dashStyle=this.limitDashStyle){
      return {
        id:id,
        typeTransKey: "growth_reference_short",
        values:values,
        color:color,
        dashStyle:dashStyle,
        lineWidth:this.limitLineWidth,
        marker:{enabled:false}
      }
    },
    updateHuntCurve: function({a,b,c}){
      this.combined.cultureChartMeasureTypes = 'data' in this.combined.cultureChartMeasureTypes ? this.combined.cultureChartMeasureTypes.data : this.combined.cultureChartMeasureTypes
      // Update hunt curve to follow until end of prediction

      if(this.combined.cultureChartMeasureTypes.sensors!==undefined && this.combined.cultureChartMeasureTypes.sensors.length>0){
        let x = undefined;
        if(this.prediction.predictionValues.length>0){
          // removes the last added values for hunt (for the prediction) because they will be added later

          if(this.prediction.lastPredictSize>0){
            this.hunt.lastHuntX = this.hunt.lastHuntX.slice(0, -(this.prediction.lastPredictSize))
          }
          let nToAdd = this.prediction.predictionValues.length - 1
          let currentFrequencyHours = this.hunt.allCombineFreq.find(i=>i.value===this.hunt.selectedCombineFreq).hours
          let startXToAdd = this.hunt.lastHuntX[this.hunt.lastHuntX.length-1]

          const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
          let xToAdd = range(startXToAdd,startXToAdd+nToAdd*currentFrequencyHours,currentFrequencyHours)
          x = this.hunt.lastHuntX.concat(xToAdd)
        }
        // noinspection JSUnresolvedVariable
        let xx = x === undefined ? this.combined.cultureChartMeasureTypes.sensors[0].values.map(v=>v.hourAfterNouaison) : x
        this.hunt.lastHuntX = xx
        this.hunt.huntParameters = {a:a,b:b,c:c}
        let values = huntCurve(xx,this.hunt.huntParameters.a,this.hunt.huntParameters.b,this.hunt.huntParameters.c)
        this.hunt.huntValues = []
        this.combined.cultureChartMeasureTypes.sensors[0].values.map(v=>v.timestamp).forEach((t,i)=>{
          this.hunt.huntValues.push({timestamp:t,value:values[i]})
        })

        // we add the missing huntValues
        if(xx.length>= this.hunt.huntValues.length){
          let lastTimestamp = this.hunt.huntValues[this.hunt.huntValues.length-1].timestamp
          for(let i = this.hunt.huntValues.length; i<xx.length;i++){
            let newTimeStamp = lastTimestamp+(xx[i]-xx[i-1])*1000*60*60
            this.hunt.huntValues.push({timestamp:newTimeStamp,value:values[i]})
            lastTimestamp = newTimeStamp
          }
        }

        if(!this.hunt.isMeasuresLoadedHunt){
          let values = {id:"(Hunt)",typeTransKey:"growth_reference",values:this.hunt.huntValues, color:this.huntColor}
          if(this.combined.cultureChartMeasureTypes.sensors.length > 1){
            this.combined.cultureChartMeasureTypes.sensors[1] = values
          }else{
            this.combined.cultureChartMeasureTypes.sensors.push(values)
          }

          //Add limits
          let averageHunt = this.hunt.huntValues.reduce((tot, v)=> tot+v.value,0)/this.hunt.huntValues.length
          let valuesUpperLimit = this.combined.cultureChartMeasureTypes.sensors[1].values.map(i=>{return{timestamp:i.timestamp,value:i.value+this.limitMultiplier*averageHunt}})
          let upperLimit = this.createLimitValues(`(Hunt)+${(this.limitMultiplier*100).toFixed(1)}%`,valuesUpperLimit)
          if(this.combined.cultureChartMeasureTypes.sensors.length>2){
            //Replace upper limit
            this.combined.cultureChartMeasureTypes.sensors[2] = upperLimit
          } else{
            //Add upper limit
            this.combined.cultureChartMeasureTypes.sensors.push(upperLimit)
          }

          let valuesBottomLimit = this.combined.cultureChartMeasureTypes.sensors[1].values.map(i=>{return{timestamp:i.timestamp,value:i.value-this.limitMultiplier*averageHunt}})
          let bottomLimit = this.createLimitValues(`(Hunt)-${(this.limitMultiplier*100).toFixed(1)}%`,valuesBottomLimit)
          if(this.combined.cultureChartMeasureTypes.sensors.length>3){
            //Replace bottom limit
            this.combined.cultureChartMeasureTypes.sensors[3] = bottomLimit
          } else {
            //Add bottom limit
            this.combined.cultureChartMeasureTypes.sensors.push(bottomLimit)
          }

          let valuesHuntAsymptote = this.combined.cultureChartMeasureTypes.sensors[1].values.map(i=>{return{timestamp:i.timestamp,value:a}})
          let huntAsymptote = this.createLimitValues(this.$t('pages.culture_show.hunt.asymptote'),valuesHuntAsymptote, 'black','Dot')
          if(this.combined.cultureChartMeasureTypes.sensors.length>4){
            this.combined.cultureChartMeasureTypes.sensors[4] = huntAsymptote
          } else {
            this.combined.cultureChartMeasureTypes.sensors.push(huntAsymptote)
          }

          //cheat to signal a change in watch function for CultureChart
          this.combined.cultureChartMeasureTypes = {...this.combined.cultureChartMeasureTypes}


          this.hunt.isMeasuresLoadedHunt = true
          this.hunt.loadingMeasuresHunt = false;
        }

        //Means there's a prediction, so we have to update the lastPredictSize
        if(this.combined.cultureChartMeasureTypes!==undefined && this.combined.cultureChartMeasureTypes.sensors!==undefined && this.combined.cultureChartMeasureTypes.sensors.length>5){
          this.prediction.lastPredictSize = this.combined.cultureChartMeasureTypes.sensors[5].values.length
        }
      }
    },
  }
}
</script>

<style scoped>

</style>