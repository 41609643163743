<template>
  <div>
    <!-- Add material (only admin can do this) -->
    <div v-if="this.$store.getters.userIsAdmin">
      <button @click="toggleAddMaterialModal" class="mb-4 btn btn--success">
        <i class="fas fa-plus mr-2"></i>{{ $t('pages.inventory.addMaterial') }}
      </button>
      <AddMaterialModal 
        @newTransmitterCreated="newTransmitterCreated" 
        @newSensorCreated="newSensorCreated" 
        :visible="addMaterialModal.isVisible" 
        :toggle="toggleAddMaterialModal" 
        :user-id="userId"
      />
    </div>

    <!-- Conteneur principal pour aligner les sections côte à côte -->
    <div class="inventory-wrapper">
      <!-- All transmitters -->
      <div v-if="isLoaded.transmitters" class="inventory-container">
        <div class="card">
          <h2 class="card__title">
            <i class="fas fa-broadcast-tower mr-2"></i>
            <span class="title-text">Tous les dispositifs émetteurs </span>
            <span class="title-count">({{ transmittersTable.data.length }})</span>
          </h2>
          <h3 v-html="$t('pages.inventory.cards.transmitters.subTitle', {usedCount: this.transmittersUsedCount, freeCount: this.transmittersFreeCount})"></h3>
          <data-table class="mt-4" v-if="transmittersTable.data" :columns="transmittersTable.columns" :data="transmittersTable.data">
            <template v-slot:actions="{ row }">
              <router-link :to="row.actions.see.to" class="btn btn-primary">{{ row.actions.see.text }}</router-link>
              <router-link v-if="row.actions.edit" :to="row.actions.edit.to" class="btn btn-primary">{{ row.actions.edit.text }}</router-link>
            </template>
          </data-table>
        </div>
      </div>

      <!-- All sensors -->
      <div v-if="isLoaded.sensors" class="inventory-container">
        <div class="card">
          <h2 class="card__title sensor-view">
            <i class="fas fa-microchip mr-2"></i>
            <span class="title-text">Tous les capteurs </span>
            <span class="title-count">({{ sensorsTable.data.length }})</span>
          </h2>
          <h3 v-html="$t('pages.inventory.cards.transmitters.subTitle', {usedCount: this.sensorsUsedCount, freeCount: this.sensorsFreeCount})"></h3>
          <data-table class="mt-4" v-if="sensorsTable.data" :columns="sensorsTable.columns" :data="sensorsTable.data">
            <template v-slot:actions="{ row }">
              <router-link :to="row.actions.see.to" class="btn btn-primary">{{ row.actions.see.text }}</router-link>
              <router-link v-if="row.actions.edit" :to="row.actions.edit.to" class="btn btn-primary">{{ row.actions.edit.text }}</router-link>
            </template>
          </data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetRequest, displayAlertSuccess } from "../../utils";
import { API_USERS_SENSORS_ALL, API_USERS_TRANSMITTERS_ALL } from "../../utils/constants";
import DataTable from "../../components/general/datatable/data-table";
import AddMaterialModal from "../../components/modals/addMaterialModal";

export default {
  name: "Inventory",
  components: { DataTable, AddMaterialModal },
  props: {
    userIdProps: {
      type: [Number, String],
      required: false 
    }
  },
  data: function() {
    return {
      userId: this.userIdProps === undefined ? this.$store.state.user.id : this.userIdProps, 
      isLoaded: {
        transmitters: false,
        sensors: false
      },
      transmittersTable: {
        columns: {},
        data: null
      },
      sensorsTable: {
        columns: {
          id: this.$t('words.id'),
          type: this.$t('words.type'),
          state: this.$t('words.state'),
          actions: this.$t('words.actions'),
        },
        data: null
      },
      addMaterialModal: {
        isVisible: false
      }
    };
  },
  mounted() {
    this.transmittersTable.columns.id = this.$t('words.id');
    // Only admin can see the TTN id
    if (this.$store.getters.userIsAdmin) {
      this.transmittersTable.columns.ttnId = "TTN id";
    }
    this.transmittersTable.columns.type = this.$t('words.type');
    this.transmittersTable.columns.state = this.$t('words.state');
    this.transmittersTable.columns.actions = this.$t('words.actions');

    // Load data
    this.fetchTransmitters();
    this.fetchSensors();
  },
  computed: {
    transmittersUsedCount: function() {
      return this.transmittersTable.data.filter(t => t.used).length;
    },
    transmittersFreeCount: function() {
      return this.transmittersTable.data.filter(t => !t.used).length;
    },
    sensorsUsedCount: function() {
      return this.sensorsTable.data.filter(s => s.used).length;
    },
    sensorsFreeCount: function() {
      return this.sensorsTable.data.filter(s => !s.used).length;
    }
  },
  methods: {
    fetchTransmitters: function() {
      this.isLoaded.transmitters = false;
      apiGetRequest(API_USERS_TRANSMITTERS_ALL(this.userId))
        .then((res) => {
          this.transmittersTable.data = res.data.map((t) => {
            t.type = `${this.$t('backend_trans_keys.' + t.typeTransKey)}`;
            t.state = t.used === true ? this.$t('words.state-used') : this.$t('words.state-free');
            t.actions = {
              see: {
                to: { name: 'transmitter_show', params: { id: t.id } },
                text: this.$t('words.see')
              },
              // Supprimer complètement edit si l'utilisateur n'est pas admin
              ...(this.$store.getters.userIsAdmin ? {
                edit: {
                  to: { name: 'transmitter_edit', params: { id: t.id } },
                  text: this.$t('words.edit')
                }
              } : {})
            };
            return t;
          });
          this.isLoaded.transmitters = true;
        });
    },
    fetchSensors: function() {
      this.isLoaded.sensors = false;
      apiGetRequest(API_USERS_SENSORS_ALL(this.userId))
        .then((res) => {
          this.sensorsTable.data = res.data.filter(s => !s.linkedToWeatherStation).map((s) => {
            s.type = `${this.$t('backend_trans_keys.' + s.typeTransKey)}`;
            s.state = s.used === true ? this.$t('words.state-used') : this.$t('words.state-free');
            s.actions = {
              see: {
                to: { name: 'sensor_show', params: { id: s.id } },
                text: this.$t('words.see')
              },
              // Supprimer complètement edit si l'utilisateur n'est pas admin
              ...(this.$store.getters.userIsAdmin ? {
                edit: {
                  to: { name: 'sensor_edit', params: { id: s.id } },
                  text: this.$t('words.edit')
                }
              } : {})
            };
            return s;
          });
          this.isLoaded.sensors = true;
        });
    },
    toggleAddMaterialModal: function() {
      this.addMaterialModal.isVisible = !this.addMaterialModal.isVisible;
    },
    newTransmitterCreated: function(transmitterId) {
      const successMsg = this.$t('pages.inventory.addMaterialModal.forms.transmitter.success', { id: transmitterId });
      displayAlertSuccess(successMsg);
      this.fetchTransmitters();
    },
    newSensorCreated: function(sensorId) {
      const successMsg = this.$t('pages.inventory.addMaterialModal.forms.sensor.success', { id: sensorId });
      displayAlertSuccess(successMsg);
      this.fetchSensors();
    }
  }
};
</script>

<style scoped>
/* Encadrement des inventaires émetteurs et capteurs */
.inventory-container {
  width: 52%; /* Largeur */
  border: none; 
  outline: 1px solid rgba(0, 0, 0, 0.1); /* Bordure fine */
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 12px; /* Coins arrondis */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Ombre */
  overflow: auto; 
}

/* Conteneur flex pour aligner les sections côte à côte */
.inventory-wrapper {
  display: flex; 
  justify-content: space-between; 
  gap: 2%; /* Espace entre les colonnes */
}

@media (max-width: 768px) {
  /* Adaptation pour les petits écrans */
  .inventory-wrapper {
    flex-direction: column; /* Empiler les éléments sur les petits écrans */
  }
  .inventory-container {
    width: 100%; /* Chaque conteneur prend 100% de la largeur sur petit écran */
  }
}

/* Styles pour les titres */
.card__title {
  font-size: 1.1rem; /* Taille du titre */
  font-weight: bold; /* Titre en gras */
  margin-bottom: 8px;
  border-bottom: 2px solid #7e8388;
  display: flex; /* Aligne le texte et le nombre */
  align-items: center;
  text-decoration: none; 
  width: 45%;
}

.sensor-view {
  font-size: 1.1rem; /* Taille du titre */
  font-weight: bold; /* Titre en gras */
  margin-bottom: 8px;
  border-bottom: 2px solid #7e8388;
  display: flex; /* Aligne le texte et le nombre */
  align-items: center;
  text-decoration: none; 
  width: 31%;
}

.title-text {
  margin-right: 8px; /* Espace entre le texte et le nombre */
}

.title-count {
  font-size: 1.2rem; /* Taille du nombre */
  font-weight: bold; /* Nombre en gras */
  color: #3e86d4; /* Couleur du nombre */
}

.title-text {
  margin-right: 8px; /* Espace entre le texte et le nombre */
}

.inventory-container:hover {
  transform: scale(1.02); /* Effet d'agrandissement */
  transition: transform 0.3s ease; 
}
</style>