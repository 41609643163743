<template>
  <div v-if="isLoaded.series">


      <!-- Warning message in case of recent measures, need to check to be able to show the delete button -->
      <div v-if="isSeriesCurrentlyMeasures">
        <div class="mb-6">
          {{ $t('pages.history_seriesShow.measureInProgressWarningMsg') }}
          <input class="cursor-pointer ml-2 align-middle" style="width: 15px; height: 15px" type="checkbox" v-model="wantToSeeDeleteBtn">
        </div>
      </div>

      <!-- Delete series -->
      <div v-if="!isSeriesCurrentlyMeasures || wantToSeeDeleteBtn">
        <confirm-modal @cancel="toggleDeleteModal" @yes="deleteSeries" :visible="deleteModal.isVisible" :toggle="toggleDeleteModal">
          <p v-html="$t('pages.history_seriesShow.deleteModal.title')"></p>
          <p class="mt-2 italic"><i class="fas fa-exclamation-triangle mr-2 text-yellow-400"></i>{{ $t('pages.history_seriesShow.deleteModal.info') }}</p>
        </confirm-modal>
        <button @click="toggleDeleteModal" class="mb-4 btn btn--danger"><i class="fas fa-trash-alt mr-2"></i>{{ $t('pages.history_seriesShow.deleteBtn') }}</button>
      </div>


      <!-- General information -->
      <div class="card">
        <h2 class="card__title">{{ $t('pages.history_seriesShow.cards.generalInfo.title') }}</h2>
        <div class="flex justify-items-start">
          <div>
            <div>{{ $t('pages.history_seriesShow.cards.generalInfo.id') }} : </div>
            <div>{{ $t('pages.history_seriesShow.cards.generalInfo.culture') }} : </div>
            <div>{{ $t('pages.history_seriesShow.cards.generalInfo.transmitters') }} : </div>
            <div>{{ $t('pages.history_seriesShow.cards.generalInfo.sensors') }} : </div>
            <div>{{ $t('pages.history_seriesShow.cards.generalInfo.sensorsType') }} : </div>
          </div>

          <div class="ml-4 font-semibold">
            <div>{{ measureUuid }}</div>
            <div>{{ series.culture.name }} ({{ $t('backend_trans_keys.'+series.culture.typeTransKey) + " " + $t('backend_trans_keys.'+series.culture.varietyTransKey) }})</div>
            <div>{{ series.transmittersId.length === 0 ? '-' : series.transmittersId.join(', ') }}</div>
            <div>{{ series.sensorsId.length === 0 ? '-' : series.sensorsId.join(', ') }}</div>
            <div>{{ $t('backend_trans_keys.'+series.sensorTypeTransKey) }}</div>
          </div>
        </div>
      </div>

      <!-- Measures -->
      <div>

        <div class="mt-4">
          <btn-with-loader class="mt-4" :loading="loadingExportCSV" :class-btn-type="'btn--primary'" @clicked="downloadCsv"><i class="fas fa-download mr-2"></i>CSV</btn-with-loader>
        </div>

        <!-- Time period selection -->
        <select-time-period :default-value="selectedTimePeriod" :default-show-data-picker="showDatePicker" :default-start-date="startDate" :default-end-date="endDate" @valueChanged="timePeriodChanged" @submitCustomDate="submitCustomPeriod" class="mt-3"/>

        <!-- All measures chart cards -->
        <div v-for="measureType in series.measures" :key="measureType.id" class="card mt-4">
          <div class="flex justify-between">
            <h2 class="card__title">{{ $t('backend_trans_keys.'+measureType.typeTransKey) }}</h2>
            <router-link :to="{name: 'history_seriesEdit', params: { measureUuid: measureUuid, measureTypeId: measureType.id }}">
              <button class="btn btn--warning"><i class="mr-2 fas fa-pencil-alt"></i> {{ $t('pages.history_seriesShow.cards.charts.editMeasures') }}</button>
            </router-link>
          </div>

          <div class="overflow-x-auto">
            <div style="min-width: 600px">
              <highcharts :options="measureType.chartOptions" ref="lineCharts" constructor-type="chart"/>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import SelectTimePeriod from "../../../../components/charts/SelectTimePeriod";
import {Chart} from 'highcharts-vue'
import {getFromAndToQueryParam, getFromQueryParam} from "../../../../utils/chartUtils";
import {
  apiGetRequest,
  apiPostRequest,
  displayAlertError,
  displayAlertSuccess,
  generateDownloadCsvLink
} from "../../../../utils";
import {
  API_HISTORY_SERIES_CSV_EXPORT,
  API_HISTORY_SERIES_DELETE,
  API_HISTORY_SERIES_SHOW
} from "../../../../utils/constants";
import {getLineChartOptionsByMeasureType} from "../../../../utils/chartUtils";
import ConfirmModal from "../../../../components/modals/confirm-modal";
import moment from "moment";
import BtnWithLoader from "../../../../components/general/btn-with-loader";

export default {
  name: "SeriesHistoryShow",
  components: {SelectTimePeriod, highcharts: Chart, ConfirmModal, BtnWithLoader},
  props: {
    measureUuid: {
      type: [String],
      required: true
    }
  },
  data: function() {
    return {
      isLoaded: {
        series: false
      },
      selectedTimePeriod: 4,
      showDatePicker: false,
      startDate:"",
      endDate:"",
      series: {},
      deleteModal: {
        isVisible: false,
      },
      wantToSeeDeleteBtn: false,
      loadingExportCSV: false,
    }
  },
  mounted() {
    this.loadSeries()
  },
  computed: {
    /**
     * Check if the last measures of the series came from less than 6 hours ago.
     * @returns {boolean} True if the last measures from the series is from less than 6 hours ago, false otherwise
     */
    isSeriesCurrentlyMeasures: function() {
      const hours6 = 6 * 3600
      return this.series.lastMeasureAt !== null && Math.abs(moment().unix() - this.series.lastMeasuredAt) <= hours6
    }
  },
  methods: {

    /**
     * Load the series from the API
     * It will fetch all details about the series and measures classified by MeasureType and generate all charts
     * todo: refactor name for consistency with app/transmitter/TransmitterShow.vue
     */
    loadSeries: function() {
      const fromQueryParam = getFromQueryParam(this.selectedTimePeriod)
      this.refreshCharts(fromQueryParam)
    },
    timePeriodChanged: function(selectedTimePeriod){
      this.selectedTimePeriod = selectedTimePeriod
      if(selectedTimePeriod !== 5){
        this.loadSeries()
        this.showDatePicker = false
      }else{
        this.showDatePicker = true
      }
    },
    submitCustomPeriod: function(rangeDates){
      this.startDate = rangeDates.start
      this.endDate = rangeDates.end
      const fromAndToQueryParams = getFromAndToQueryParam(rangeDates)
      this.refreshCharts(fromAndToQueryParams)
    },

    refreshCharts: function(queryParams) {

      this.isLoaded.series = false
      this.series = {}

      apiGetRequest(API_HISTORY_SERIES_SHOW(this.measureUuid, queryParams))
          .then((res) => {
            this.series = res.data
            this.generateCharts()
            this.isLoaded.series = true
          })
    },

    /**
     * Update (extends) the local measures variable to add all measures data provided by the API.
     * The updated value will contain all data and options for the chart displaying
     */
    generateCharts: function() {
      for (const key in this.series.measures) {
        const measureType = this.series.measures[key]
        measureType["chartOptions"] = getLineChartOptionsByMeasureType(measureType, () => {
          const errorMsg = this.$t('pages.sensor_show.error-export')
          displayAlertError(errorMsg)
        })
      }
    },
    /**
     * Toggle the delete modal
     */
    toggleDeleteModal: function() {
      this.deleteModal.isVisible = !this.deleteModal.isVisible
    },
    /**
     * definitively delete the current measurement series and all its measures
     */
    deleteSeries: function() {
      apiPostRequest(API_HISTORY_SERIES_DELETE(this.measureUuid), {})
          .then(() => {
            const successMsg = this.$t('pages.history_seriesShow.deleteModal.success', {'id': this.measureUuid})
            displayAlertSuccess(successMsg)
            const route = this.$store.getters.userIsAdmin
                ? {name: 'history_farmerCultureShow', params: {farmerIdProps: this.series.farmerId, cultureIdProps: this.series.culture.id }}
                : {name: 'history_cultureShow', params: {cultureIdProps: this.series.culture.id }}

            this.$router.push(route)
          })
          .finally(() => {
            this.toggleDeleteModal()
          })
    },
    /**
     * Generate csv export that contains all measures for this current measurement series
     * For ajax call, we need to recreate a link and simulate a click to download the real file.
     */
    downloadCsv: function() {
      if(!this.loadingExportCSV){
        this.loadingExportCSV = true
        apiGetRequest(API_HISTORY_SERIES_CSV_EXPORT(this.measureUuid))
            .then((res) => {
              const link = generateDownloadCsvLink(res.data, res.headers['x-filename'])
              link.click();
            })
            .finally(() => this.loadingExportCSV = false)
      }

    },
  }
}
</script>

<style scoped>

</style>
